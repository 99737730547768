<template>

  <delivery-address-modal ref="addressModal" :product-id="currentProduct" :international="internationalDelivery" @modal-result-ok="saveProductAddress"></delivery-address-modal>

  <asModalDialog
    heading-text="3d esikatselu"
    :show-ok="false"
    :show-cancel="false"
    width="80%"
    min-width="500px"
    ref="previewModal"
  >
  <ThreeDPreview
      :texture-width=preview.textureWidth
      :texture-height=preview.textureHeight
      :material-name="preview.gblVariable"
      :textures="preview.textures"
      :texture-uri="preview.gblPath"
  />

  </asModalDialog>
  <asModalDialog
    heading-text="3d esikatselu"
    :show-ok="false"
    :show-cancel="false"
    width="80%"
    min-width="500px"
    ref="mariannePreview"
  >
  <ThreeDPreviewMultipleObjects
    :data="threeDdataSetMarianne"
  />
  </asModalDialog>

  <asModalDialog heading-text="3d esikatselu" :show-ok="false" :show-cancel="false" width="80%" min-width="500px"
      ref="customCandyBoxPreview">
      <ThreeDPreviewMultipleObjects
        :data="threeDdataSetCandyBox"
      />
  </asModalDialog>

  <canvas-image
  ref="smallSideCanvas"
  :image-width=1024
  :image-height=61
  :options="{
    backgroundColor: 'transparent'
  }"
  />

  <canvas-image
    ref="bigSideCanvas"
    :image-width=1024
    :image-height=300
    :options="{
      backgroundColor: 'transparent'
    }"
  />

  <asModalDialog ref="deleteProduct" heading-text="Oletko varma?" ok-button-text="Kylla"
  cancel-button-text="Ei">
    <p>Oletko varma, että haluat poistaa tuotteen ostoskorista?</p>
  </asModalDialog>

  <CustomCandyBoxCanvas
    ref="customCandyBoxCanvas" :canvasText=getCandyBoxText
    :canvasTitle=getCandyBoxTitle :canvasSignature=getCandyBoxSignature
    :fontStyle="{ 'font-family': getCandyBoxFont }"
    :bgColor="getCandyBoxColor"
    :productlogoUrl="getCandyBoxLogo"
    style="display: none;"
  />

  <MarianneCanvas
  ref="marianneCanvas" :canvasText="getMarianneText"
  :canvasTitle="getMarianneTitle" :canvasSignature="getMarianneSignature"
  />

  <div v-for="(product, index) in products" :key="product.id" class="columns is-multiline is-size-5 is-vcentered is-mobile products-list" v-bind:class="{ 'grey-box': index % 2 == 0 }" data-testid="product">
    <div class="column has-text-left is-hidden-tablet mb-5 has-text-weight-bold" data-testid="name">
      <p v-if="product.product.name == 'domino'">Domino</p>
      <p v-else-if="product.product.name == 'blue'">Konvehtirasia</p>
      <p v-else-if="product.product.name == 'bar' && product.flavor != null">Suklaalevy / {{product.flavor.flavor}}</p>
      <p v-else-if="product.product.name == 'bar' && product.flavor == null">Suklaalevy</p>
      <p v-else-if="product.product.name == 'marianne'">Marianne</p>
      <p v-else-if="product.product.name == 'custom-candy-box' && product.flavor != null">Custom Candy Box / {{product.flavor.flavor}} </p>
      <p v-else-if="product.product.name == 'custom-candy-box' && product.flavor == null">Custom Candy Box</p>
      <p v-else-if="product.product.name == 'calendar-b2b' && product.flavor == null">Fazer Oma Kalenteri</p>
      <p v-else-if="product.product.name == 'calendar'">Fazer suklaajoulukalenteri omalla kuvalla #{{index+1}}</p>
    </div>
    <div class="is-hidden-mobile" data-testid="name">
      <p v-if="product.product.name == 'domino'" class="has-text-centered pl-5">Domino</p>
      <p v-else-if="product.product.name == 'blue'" class="has-text-centered pl-5">Konvehtirasia</p>
      <p v-else-if="product.product.name == 'bar' && product.flavor != null" class="has-text-centered pl-5">Suklaalevy / {{product.flavor.flavor}}</p>
      <p v-else-if="product.product.name == 'bar' && product.flavor == null" class="has-text-centered pl-5">Suklaalevy</p>
      <p v-else-if="product.product.name == 'marianne'" class="has-text-centered pl-5">Marianne</p>
      <p v-else-if="product.product.name == 'custom-candy-box' && product.flavor != null" class="has-text-centered pl-5">Custom Candy Box / {{product.flavor.flavor}}</p>
      <p v-else-if="product.product.name == 'custom-candy-box' && product.flavor == null" class="has-text-centered pl-5">Custom Candy Box</p>
      <p v-else-if="product.product.name == 'calendar-b2b' && product.flavor == null" class="has-text-centered pl-5">Fazer Oma Kalenteri</p>
      <p class="pl-2" v-else-if="product.product.name == 'calendar'">Fazer suklaajoulukalenteri omalla kuvalla #{{index+1}}</p>
    </div>
    <div class="is-hidden-mobile is-flex table-wrapper">
      <div v-if="product.product.name == 'domino' && product.orientation == 'OL'" class="column is-2 has-text-centered">
        <figure class="image domino-thumbnail-picture">
          <img :src="product.image.horizontal_thumbnail_url" />
          <div class="logo-small landscape" :style="previewLogoCss(product.croppied_logo_url)"></div>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'domino' && product.orientation == 'OP'" class="column vertical-thumbnail-column is-2 has-text-centered">
        <div class="columns is-mobile">
          <div class="column is-6 is-offset-3">
            <figure class="image">
              <img :src="product.image.vertical_thumbnail_url" style="height: 120px; width: auto;"/>
              <div class="logo-small portrait" :style="previewLogoCss(product.croppied_logo_url)"></div>
            </figure>
          </div>
        </div>
      </div>
      <div v-else-if="product.product.name == 'calendar'" class="column vertical-thumbnail-column is-2 has-text-centered">
        <div class="columns is-mobile">
          <div class="column is-6 is-offset-3">
            <figure class="image" style="height: 120px; width: 80px;">
              <img :src="product.image.thumbnail_url" style="height: 120px; width: 80px;"/>
            </figure>
          </div>
        </div>
      </div>      
      <div v-else-if="product.product.name == 'marianne'" class="column is-2 has-text-centered">
        <figure class="image marianne-thumbnail">
          <img v-if="product.back_thumbnail_url != ''" :src="product.back_thumbnail_url" style="background-color: #15256B;"/>
          <img v-else :src="`${publicPath}imgs/default-marianne-cover.png`" style="background-color: #15256B;"/>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'custom-candy-box'" class="column is-2 has-text-centered">
        <figure class="image marianne-thumbnail">
          <img v-if="product.back_thumbnail_url != ''" :src="product.back_thumbnail_url" style="border-left: 15px solid; border-right: 15px solid; border-bottom: 15px solid; box-sizing: content-box; height: 105px;"
          :style="{borderColor: product.background_color}" />
          <img v-else :src="`${publicPath}imgs/default-marianne-cover.png`" style="background-color: #15256B;"/>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'calendar-b2b'" class="column is-2 has-text-centered">
        <figure class="image marianne-thumbnail">
          <img v-if="product.image.thumbnail_url != ''" :src="product.image.thumbnail_url" style="border-left: 15px solid; border-right: 15px solid; border-bottom: 15px solid; box-sizing: content-box; height: 105px; border-color: transparent;"/>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'bar'" class="column is-2 has-text-centered">
        <figure class="image">
          <img v-if="product.thumbnail_url != ''" :src="product.thumbnail_url" style="background-color: #15256B;"/>
          <img v-else :src="product.product.json_data.front_thumbnail" style="background-color: #15256B;"/>
        </figure>
      </div>
      <div v-else class="column is-2 has-text-centered">
        <figure class="image">
          <img v-if="product.thumbnail_url != ''" :src="product.thumbnail_url" style="background-color: #15256B;"/>
          <img v-else :src="`${publicPath}imgs/default-blue.png`" style="background-color: #15256B;"/>
        </figure>
      </div>
      <div class="column is-2" v-if="product.product.name == 'domino'">
         <figure class="image domino-thumbnail-text">
           <img v-if="product.image.text_image_thumbnail_url != ''" :src="product.image.text_image_thumbnail_url"/>
           <img v-else :src="`${publicPath}imgs/default-domino.png`"/>
         </figure>
      </div>
      <div class="column is-2" :class="{'mb-5': product.product.name == 'custom-candy-box' }" v-else>
        <figure class="image marianne-thumbnail" v-if="product.product.name == 'marianne'">
          <img v-if="product.thumbnail_url != ''" :src="product.thumbnail_url"/>
          <img v-else :src="`${publicPath}imgs/default-marianne-card.png`"/>
        </figure>
        <figure class="image marianne-thumbnail" v-if="product.product.name == 'custom-candy-box'">
          <img v-if="product.thumbnail_url != ''" :src="product.thumbnail_url" style="border-left: 15px solid; border-right: 15px solid; border-bottom: 15px solid; border-top: 15px solid; box-sizing: content-box;"
          :style="{borderColor: product.background_color}" />
          <img v-else :src="`${publicPath}imgs/default-marianne-card.png`"/>
        </figure>
        <figure class="image" v-else-if="product.product.name == 'bar'">
          <img v-if="product.back_thumbnail_url != ''" :src="product.back_thumbnail_url" style="background-color: #15256B;"/>
          <img v-else :src="product.product.json_data.back_thumbnail" style="background-color: #15256B"/>
        </figure>
        <figure class="image" v-else-if="product.product.name == 'blue'">
          <img v-if="product.back_thumbnail_url != ''" :src="product.back_thumbnail_url" style="background-color: #15256B; transform: rotate(180deg);"/>
          <img v-else :src="`${publicPath}imgs/default-blue.png`" style="background-color: #15256B; transform: rotate(180deg);"/>
        </figure>
      </div>
      <div class="column is-4 has-text-centered">
        <span class="icon is-medium mt-3 is-clickable mr-4" @click="openPreview(product.id)" v-if="product.product.name != 'calendar' && product.product.name != 'calendar-b2b'">
          <i class="fas fa-2x fa-cube"></i>
        </span>
        <span v-if="isEditable" class="icon is-medium mt-3 is-clickable mr-4" @click="editProduct(product)">
          <i class="fas fa-2x fa-edit"></i>
        </span>
        <span v-if="isEditable" class="icon is-medium mt-3 is-clickable mr-4" @click="deleteProduct(product)">
          <i class="fas fa-2x fa-trash"></i>
        </span>
        <span v-if="isEditable && product.product.name != 'calendar'" class="icon is-medium mt-3 is-clickable mr-4" @click="cloneProduct(product)">
          <i class="fas fa-2x fa-clone"></i>
        </span>
      </div>
      <div class="column is-2 has-text-centered is-relative" style="padding-top: 40px; line-height: 30px;">
        <div class="text-wrapper is-inline-block">
          <productAmount :is-editable="isEditable" v-model="product.amount" :product="product" class="is-size-5"></productAmount>
        </div>
      </div>
      <div class="column is-2 has-text-right pt-0">
        <p class="position" data-testid="price">à {{ parseFloat(this.$store.getters.getVolumeDiscount(product.product.name)).toFixed(2) }} € = {{ parseFloat(product.amount * this.$store.getters.getVolumeDiscount(product.product.name)).toFixed(2) }} €</p>
      </div>
    </div>

    <div v-if="(product.product.name == 'bar' || product.product.name == 'marianne' || product.product.name == 'custom-candy-box')" class="column is-12 is-flex is-align-items-center py-0 column-h-reset">
      <div class="columns">
        <div class="column is-12 is-flex is-align-items-center py-0 column-h-reset">
          <span v-if="product.csv_status == 'NO_CSV_NEEDED'" class="special-delivery-text mb-2">Valmiiksi personoitu tuote</span>
          <span v-else class="special-delivery-text mb-2">Versiotietojen toimitus tiedostolla</span>
        </div>
      </div>
    </div>
    <div v-if="product.product.name == 'domino' || product.product.name == 'blue' || product.product.name == 'calendar-b2b'" class="column is-12 is-flex is-align-items-center py-0 column-h-reset">
      <span class="special-delivery-text mb-2">Valmiiksi personoitu tuote</span>
    </div>

    <div v-if="product.product.name == 'bar' || product.product.name == 'marianne' || product.product.name == 'custom-candy-box'" class="column is-12 is-flex is-align-items-center py-0 column-h-reset">
      <span v-if="getOrderDeliveryType == 'ALL_TO_MULTIPLE_ADDRESS' || (getOrderDeliveryType == 'MIXED' && (product.address_type == 'FINLAND_MAILBOX' || product.address_type == 'INTERNATIONAL_MAILBOX') && product.address_type != undefined)" class="icon is-medium mr-3 special-delivery-icon">
        <i class="fa-solid fa-envelopes-bulk"></i>
      </span>

      <span v-else-if="(getOrderDeliveryType != 'MIXED') || (getOrderDeliveryType == 'MIXED' && product.address_type != undefined) || (getOrderDeliveryType == 'MIXED' && product.delivery_address != undefined)" class="icon is-medium mr-3 special-delivery-icon">
        <i class="fa-solid fa-truck"></i>
      </span>
      <div class="columns is-fullwidth">
        <div class="column is-12 is-flex is-align-items-center py-0 column-h-reset">
          <span v-if="getOrderDeliveryType == 'MIXED' && product.address_type == 'INTERNATIONAL_MAILBOX'" class="special-delivery-text mb-2">Toimitus suoraan vastaanottajille ulkomailla</span>
          <span v-else-if="getOrderDeliveryType == 'MIXED' && product.address_type == 'INTERNATIONAL_FREIGHT'" class="special-delivery-text mb-2">Toimitus rahdilla valittuun osoitteeseen ulkomailla</span>
          <span v-else-if="getOrderDeliveryType == 'ALL_TO_MULTIPLE_ADDRESS' || (getOrderDeliveryType == 'MIXED' && product.address_type == 'FINLAND_MAILBOX')" class="special-delivery-text mb-2">Toimitus postilaatikkoon Suomessa</span>
          <span v-else-if="getOrderDeliveryType == 'MIXED' && product.address_type == 'FINLAND_FREIGHT'" class="special-delivery-text mb-2">Toimitus rahdilla valittuun osoitteeseen Suomessa</span>
          <span v-else-if="getOrderDeliveryType == 'ALL_TO_SAME_ADDRESS'" class="special-delivery-text mb-2">Toimitus rahdilla yhteen osoitteeseen Suomessa</span>
        </div>
      </div>
    </div>
    <div v-else class="column is-12 is-flex is-align-items-center py-0 column-h-reset">
      <span class="icon is-medium mr-3 special-delivery-icon">
        <i class="fa-solid fa-truck"></i>
      </span>
      <span v-if="product.product.name == 'calendar'" class="special-delivery-text mb-2">Toimitus postipakettina yhteen osoitteeseen Suomessa</span>
      <span v-else-if="getOrderDeliveryType == 'ALL_TO_SAME_ADDRESS'" class="special-delivery-text mb-2">Toimitus rahdilla yhteen osoitteeseen Suomessa</span>
      <span v-else-if="getOrderDeliveryType == 'MIXED' && product.address_type == undefined" class="special-delivery-text mb-2"></span>
      <span v-else-if="getOrderDeliveryType == 'MIXED' && product.address_type != 'INTERNATIONAL_FREIGHT'" class="special-delivery-text mb-2">Toimitus rahdilla valittuun osoitteeseen Suomessa</span>
      <span v-else class="special-delivery-text mb-2">Toimitus rahdilla valittuun osoitteeseen ulkomailla</span>
    </div>

    <div class="column add-address-column is-12 pb-0 pt-0" v-if="getOrderDeliveryType == 'MIXED' && !product.mailbox_selected">
      <label class="add-address" v-if="product.is_address_editable || product.delivery_address==undefined">
        <input type="radio" id="freight" value="FINLAND_FREIGHT" v-model="product.address_type" @click="updateProductDeliveryType(product.id,'FINLAND_FREIGHT')">
        <span class="graphical-radio"></span>
        Erillinen toimitusosoite rahtitoimituksena Suomeen
      </label>
      <label class="add-address" v-if="(product.product.name != 'domino' && product.product.name != 'blue' && product.product.name != 'calendar-b2b') && (product.is_address_editable || product.delivery_address==undefined)">
        <input type="radio" id="mailbox" value="FINLAND_MAILBOX" v-model="product.address_type" @click="updateProductDeliveryType(product.id, 'FINLAND_MAILBOX')">
        <span class="graphical-radio"></span>
        Yksittäistoimitus vastaanottajien postilaatikkoon Suomessa (Pyydämme osoitetiedot tilauksen jälkeen)
      </label>
      <label class="add-address" v-if="getOrderDeliveryType == 'MIXED' && (product.is_address_editable || product.delivery_address==undefined)">
        <input type="radio" id="international_freight" value="INTERNATIONAL_FREIGHT" v-model="product.address_type" @click="updateProductDeliveryType(product.id, 'INTERNATIONAL_FREIGHT')">
        <span class="graphical-radio"></span>
        Toimitus rahtina ulkomaille (Vain yrityksille. Toimituskulut lisätään laskulle.)
      </label>
      <label class="add-address" v-if="getOrderDeliveryType == 'MIXED' && (product.product.name != 'domino' && product.product.name != 'blue' && product.product.name != 'calendar-b2b') && (product.is_address_editable || product.delivery_address==undefined)">
        <input type="radio" id="international_mailbox" value="INTERNATIONAL_MAILBOX" v-model="product.address_type" @click="updateProductDeliveryType(product.id, 'INTERNATIONAL_MAILBOX')">
        <span class="graphical-radio"></span>
        Yksittäistoimitus vastaanottajille ulkomaille (Vain yrityksille. Toimituskulut lisätään laskulle. Pyydämme osoitetiedot tilauksen jälkeen.)
      </label><br>
      <label class="product-address mt-5 mb-5" v-if="product.delivery_address != undefined">
        <span v-if="getOrderDeliveryType=='MIXED' && ((product.address_type == 'INTERNATIONAL_FREIGHT' && product.delivery_address.recv_country != 'Suomi') || (product.address_type == 'FINLAND_FREIGHT' && product.delivery_address.recv_country == 'Suomi'))">
          {{addressParser(product)}}</span> <span v-if="isEditable && !product.is_address_editable" class="edit-address" @click="product.is_address_editable=true;">Muokkaa
      </span>
      </label>
      <button
          class="button add-address-button is-primary mb-3 mt-2"
          :disabled="product.address_type == undefined"
          @click="openAddressModal(product)"
          v-if="((product.is_address_editable || product.delivery_address == undefined) && (product.address_type != 'INTERNATIONAL_MAILBOX' && product.address_type != 'FINLAND_MAILBOX'))"
      >
        <i class="fas fa-plus mr-5"></i>
        Lisää toimitusosoite
      </button>
      <button
          class="button add-address-button is-primary mb-3 mt-2 has-text-centered is-inline-block"
          :disabled="product.address_type == undefined"
          @click="saveAddressLineItem(product)"
          v-if="(product.address_type == 'FINLAND_MAILBOX'
           || product.address_type == 'INTERNATIONAL_MAILBOX') && !product.mailbox_selected && isEditable"
      >
        Tallenna valinta
      </button>
    </div>

    <div class="column add-address-column is-12 pb-0 pt-5" v-else-if="getOrderDeliveryType == 'MIXED' && product.mailbox_selected && isEditable">
      <label class="product-address mb-5">
        <span class="edit-address" @click="product.mailbox_selected=false;product.delivery_address=undefined">Muokkaa</span>
      </label>
    </div>

    <div class="is-hidden-tablet column is-12">
      <div class="columns is-mobile mb-0 pb-0">
        <div v-if="product.product.name == 'domino' && product.orientation == 'OL'" class="column is-4 has-text-centered mobile-icons mb-0 pb-0">
          <figure class="image">
            <img :src="product.image.horizontal_thumbnail_url" />
            <div class="logo-small landscape" :style="previewLogoCss(product.croppied_logo_url)"></div>
          </figure>
        </div>
        <div v-else-if="product.product.name == 'domino'" class="column is-4 mb-0 pb-0 vertical-thumbnail-column">
          <div class="columns is-mobile mb-0 pb-0">
            <div class="column is-6 is-offset-3 mobile-icons mb-0 pb-0">
              <figure class="image">
                <img :src="product.image.vertical_thumbnail_url"/>
                <div class="logo-small portrait" :style="previewLogoCss(product.croppied_logo_url)"></div>
              </figure>
            </div>
          </div>
        </div>
        <div v-else-if="product.product.name == 'calendar'" class="column is-4 mb-0 pb-0 vertical-thumbnail-column">
          <div class="columns is-mobile mb-0 pb-0">
            <div class="column is-6 is-offset-3 mobile-icons mb-0 pb-0">
              <figure class="image">
                <img :src="product.image.thumbnail_url"/>
                <div class="logo-small portrait" :style="previewLogoCss(product.croppied_logo_url)"></div>
              </figure>
            </div>
          </div>
        </div>        
        <div v-else-if="product.product.name == 'bar'" class="column is-4 has-text-centered mobile-icons mb-0 pb-0">
          <figure class="image" >
            <img v-if="product.thumbnail_url != ''" :src="product.thumbnail_url" style="background-color: #15256B;"/>
            <img v-else :src="product.product.json_data.front_thumbnail" style="background-color: #15256B"/>
          </figure>
        </div>
        <div v-else-if="product.product.name == 'blue'" class="column is-4 has-text-centered mobile-icons mb-0 pb-0">
          <figure class="image">
            <img v-if="product.thumbnail_url != ''" :src="product.thumbnail_url" style="background-color: #15256B;"/>
            <img v-else :src="`${publicPath}imgs/default-blue.png`" style="background-color: #15256B; transform: rotate(180deg);"/>
          </figure>
        </div>
        <div v-else-if="product.product.name == 'marianne'" class="column is-4 has-text-centered mobile-icons mb-0 pb-0">
          <figure class="image">
            <img v-if="product.back_thumbnail_url != ''" :src="product.back_thumbnail_url"/>
            <img v-else :src="`${publicPath}imgs/default-marianne-card.png`"/>
          </figure>
        </div>
        <div v-else-if="product.product.name == 'custom-candy-box'" class="column is-4 has-text-centered mobile-icons mb-0 pb-0">
          <figure class="image">
            <img v-if="product.back_thumbnail_url != ''" :src="product.back_thumbnail_url" style="border-left: 10px solid; border-right: 10px solid; border-bottom: 10px solid; box-sizing: content-box;"
          :style="{borderColor: product.background_color}"/>
            <img v-else :src="`${publicPath}imgs/default-marianne-card.png`"/>
          </figure>
        </div>
        <div class="column is-5 is-offset-3 m-auto mb-0 pb-0">
          <productAmount :is-editable="isEditable" v-model="product.amount" :product="product" class="is-size-5"></productAmount>
        </div>
      </div>
      <div class="columns is-mobile pt-0 mt-0">
        <div class="column is-3 has-text-centered pt-0 mt-0" v-if="product.product.name != 'calendar' && product.product.name != 'calendar-b2b'">
          <span class="icon is-medium mt-3 is-clickable" @click="openPreview(product.id)">
            <i class="fas fa-2x fa-cube"></i>
          </span>
        </div>
        <div class="column is-3 has-text-centered pt-0 mt-0">
          <span v-if="isEditable" class="icon is-medium mt-3 is-clickable" @click="editProduct(product)">
            <i class="fas fa-2x fa-edit"></i>
          </span>
        </div>
        <div class="column is-3 has-text-centered pt-0 mt-0">
          <span v-if="isEditable" class="icon is-medium mt-3 is-clickable" @click="deleteProduct(product)">
            <i class="fas fa-2x fa-trash"></i>
          </span>
        </div>
        <div class="column is-3 has-text-centered pt-0 mt-0">
          <span v-if="isEditable && product.product.name != 'calendar'" class="icon is-medium mt-3 is-clickable" @click="cloneProduct(product)">
            <i class="fas fa-2x fa-clone"></i>
          </span>
        </div>
      </div>
      <div class="columns is-mobile pt-0 mt-0">
        <div class="column is-12 mb-0">
          <p class="has-text-right mobile-price-summary-text" data-testid="price">à {{ parseFloat(this.$store.getters.getVolumeDiscount(product.product.name)).toFixed(2) }} € = {{ parseFloat(product.amount * this.$store.getters.getVolumeDiscount(product.product.name)).toFixed(2) }} €</p>
        </div>
      </div>
    </div>
  </div>


  <div v-if="discounts != null && discounts.code" class="columns is-size-5 border-top-grey is-multiline mb-0">
    <div class="column is-2 pb-0 has-text-centered-tablet has-text-left-mobile">
      <span class="icon is-medium has-text-light">
        <i class="fas fa-2x fa-gift"></i>
      </span>
    </div>
    <div class="column is-2">
      Kampanjakoodi {{discounts.code}}
    </div>
    <div class="column is-1 has-text-centered-tablet has-text-left-mobile">
      <div v-if="isDiscountEditable" class="svg-wrapper" @click="removeDiscount()">
        <span class="icon is-medium is-clickable">
          <i class="fas fa-2x fa-trash"></i>
        </span>
      </div>
    </div>
    <div class="column has-text-right">
      <span class="subtotal">= - {{discount_value}}</span>
    </div>
  </div>

    <!--
    ALL_TO_SAME_ADDRESS = freight
    ALL_TO_MULTIPLE_ADDRESS = mailbox + unifaun (only marianne and chocolate bar)
  -->
  <div class="column">
    <div class="columns">
      <h3 class="mb-4">Toimituskulut</h3>
    </div>
  </div>

  <div v-if="getTotalFreightPrice>0 || (getOrderDeliveryType == 'MIXED' && getTotalFreightItemsInternational>0)" class="columns is-size-5 is-mobile">
    <div class="column is-2 has-text-centered">
      <span class="icon is-medium mr-3 has-text-light">
        <i class="fas fa-2x fa-truck"></i>
      </span>
    </div>
    <div class="column is-7" style="line-height: 15px;" v-if="!getCalendarMode()">
      <span v-if="getOrderDeliveryType != 'MIXED'" class="special-delivery-text">Toimitus rahdilla yhteen toimitusosoitteeseen ({{getTotalWeight}}kg)</span>
      <span v-else class="special-delivery-text">Toimitukset rahdilla</span>
    </div>
    <div class="column is-7" style="line-height: 15px;" v-else>
      <span class="special-delivery-text">Toimitus postipakettina yhteen osoitteeseen Suomessa</span>
    </div>
    <div v-if="getOrderDeliveryType != 'MIXED'" class="column has-text-right is-3">
      <span class="subtotal" style="font-size: 13px;">= {{getTotalFreightPrice}} €</span>
    </div>
  </div>

  <div v-if="getTotalFreightPrice>0 || (getOrderDeliveryType == 'MIXED' && getTotalFreightItemsInternational>0)" class="is-size-5 is-mobile pb-5">
    <div class="columns pb-0 pt-0" v-for="(product, index) in productsWithAddresses" :key="product.id">
      <div class="column pb-0 pt-0 is-7 is-offset-2">
        <label class="add-address" v-if="product.delivery_address != null && getOrderDeliveryType == 'MIXED' && (product.address_type == 'INTERNATIONAL_FREIGHT' || product.address_type == 'FINLAND_FREIGHT')">
          {{addressParser(product)}} ({{getWeightForAddress(product)}}kg)
        </label><br>
      </div>
      <div class="column has-text-right is-3">
        <span v-if="product.delivery_address != null && product.address_type == 'INTERNATIONAL_FREIGHT'" class="subtotal" style="font-size: 13px;">= Ulkomaan toimituskustannukset lisätään laskulle</span>
        <span v-else-if="product.delivery_address != null && product.address_type == 'FINLAND_FREIGHT'" class="subtotal" style="font-size: 13px;">= {{getTotalFreightPriceForOneAddress(product)}}</span>
      </div>
    </div>
  </div>

  <div v-if="(getTotalMailboxItemsFinland>0 && getTotalMailboxItemsInternational == 0) || (getTotalMailboxItemsFinland==0 && getTotalMailboxItemsInternational>0)" class="columns is-size-5 is-mobile is-hidden-mobile">
    <div class="column is-2 has-text-centered">
      <span class="icon is-medium has-text-light">
        <i class="fas fa-2x fa-envelopes-bulk"></i>
      </span>
    </div>
    <div class="column is-7"  style="line-height: 15px;">
      <span v-if="getTotalMailboxPrice>0" class="special-delivery-text">Toimitus postilaatikkoon ({{getTotalMailboxItemsFinland}}kpl)</span>
      <span v-else class="special-delivery-text">Toimitus suoraan vastaanottajille ulkomailla ({{getTotalMailboxItemsInternational}}kpl)</span>
    </div>
    <div class="column has-text-right is-3">
      <span v-if="getTotalMailboxPrice>0" class="subtotal" style="font-size: 13px;">= {{getTotalMailboxPrice}} €</span>
      <span v-else class="subtotal" style="font-size: 13px;">= Ulkomaan toimituskustannukset lisätään laskulle</span>
    </div>
  </div>

  <div v-if="(getTotalMailboxPrice>0 && getTotalMailboxItemsInternational==0) || (getTotalMailboxPrice==0 && getTotalMailboxItemsInternational>0)" class="columns is-size-5 is-mobile is-hidden-tablet">
    <div class="column is-2 has-text-centered">
      <span class="icon is-medium has-text-light">
        <i class="fas fa-2x fa-envelopes-bulk"></i>
      </span>
    </div>
    <div class="column is-10">
      <span v-if="getTotalMailboxPrice>0" class="special-delivery-text">Toimitus postilaatikkoon ({{getTotalMailboxItemsFinland}}kpl)</span>
      <span v-else class="special-delivery-text">Toimitus suoraan vastaanottajille ulkomailla ({{getTotalMailboxItemsInternational}}kpl)</span>
    </div>
  </div>

  <div v-if="(getTotalMailboxPrice>0 && getTotalMailboxItemsInternational==0) || (getTotalMailboxPrice==0 && getTotalMailboxItemsInternational>0)"  class="columns is-size-5 is-mobile is-hidden-tablet">
    <div class="column is-12 has-text-right">
      <span v-if="getTotalMailboxPrice>0" class="subtotal" style="font-size: 13px;">= {{getTotalMailboxPrice}} €</span>
      <span v-else class="subtotal" style="font-size: 13px;">= Ulkomaan toimituskustannukset lisätään laskulle</span>
    </div>
  </div>

  <div v-if="getTotalMailboxItemsInternational>0 && getTotalMailboxItemsFinland>0" class="columns is-size-5 is-mobile">
    <div class="column is-2 has-text-centered">
      <span class="icon is-medium has-text-light">
        <i class="fas fa-2x fa-envelopes-bulk"></i>
      </span>
    </div>
    <div class="column is-7"  style="line-height: 15px;">
      <span class="special-delivery-text">Toimitukset suoraan vastaanottajille</span>
    </div>
  </div>

  <div v-if="getTotalMailboxItemsInternational>0 && getTotalMailboxItemsFinland>0" class="columns is-size-5 is-mobile is-hidden-mobile">
    <div class="column is-7 is-offset-2"  style="line-height: 15px;">
      <span class="special-delivery-text">Toimitukset suoraan vastaanottajille ulkomaille  ({{getTotalMailboxItemsInternational}}kpl)</span>
    </div>
    <div class="column has-text-right is-3">
      <span class="subtotal" style="font-size: 13px;">= Ulkomaan toimituskustannukset lisätään laskulle</span>
    </div>
  </div>

  <div v-if="getTotalMailboxItemsInternational>0 && getTotalMailboxItemsFinland>0" class="columns is-size-5 is-mobile is-hidden-mobile">
    <div class="column is-7 is-offset-2" style="line-height: 15px;">
      <span class="special-delivery-text">Toimitukset suoraan vastaanottajille Suomeen ({{getTotalMailboxItemsFinland}}kpl)</span>
    </div>
    <div class="column has-text-right is-3">
      <span class="subtotal" style="font-size: 13px;">= {{getTotalMailboxPrice}} €</span>
    </div>
  </div>



  <div v-if="getTotalMailboxItemsInternational>0 && getTotalMailboxItemsFinland>0" class="columns is-size-5 is-mobile is-hidden-tablet">
    <div class="column is-10">
      <span class="special-delivery-text">Toimitukset suoraan vastaanottajille ulkomaille ({{getTotalMailboxItemsInternational}}kpl)</span>
    </div>
  </div>

  <div v-if="getTotalMailboxItemsInternational>0 && getTotalMailboxItemsFinland>0" class="columns is-size-5 is-mobile is-hidden-tablet">
    <div class="column is-12 has-text-right">
      <span class="subtotal" style="font-size: 13px;">= Ulkomaan toimituskustannukset lisätään laskulle</span>
    </div>
  </div>

  <div v-if="getTotalMailboxItemsInternational>0 && getTotalMailboxItemsFinland>0" class="columns is-size-5 is-mobile is-hidden-tablet">
    <div class="column is-10">
      <span class="special-delivery-text">Toimitukset suoraan vastaanottajille Suomeen ({{getTotalMailboxItemsFinland}}kpl)</span>
    </div>
  </div>

  <div v-if="getTotalMailboxItemsInternational>0 && getTotalMailboxItemsFinland>0" class="columns is-size-5 is-mobile is-hidden-tablet">
    <div class="column is-12 has-text-right">
      <span class="subtotal" style="font-size: 13px;">= {{getTotalMailboxPrice}}</span>
    </div>
  </div>

  <div class="columns is-hidden-mobile bottom-pricing-info border-top-grey">
    <div class="column is-offset-2 is-one-quarter has-text-left" v-if="!getCalendarMode()">
      <p><span class="total" v-if="this.$store.getters.getTotalAmount('domino') > 0" :class="{ red: this.$store.getters.getTotalAmount('domino') < 24 }">Domino {{ this.$store.getters.getTotalAmount('domino') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('domino') < 24 && this.$store.getters.getTotalAmount('domino') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 24 kpl.
        </span>
      </p>
      <p><span class="total pt-6 is-inline-block" v-if="this.$store.getters.getTotalAmount('blue') > 0" :class="{ red: this.$store.getters.getTotalAmount('blue') < 20 }">Maitosuklaakonvehti {{ this.$store.getters.getTotalAmount('blue') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('blue') < 20 && this.$store.getters.getTotalAmount('blue') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 20 kpl.
        </span>
      </p>
      <p><span class="total pt-6 is-inline-block" v-if="this.$store.getters.getTotalAmount('bar') > 0" :class="{ red: this.$store.getters.getTotalAmount('bar') < 50 }">Fazer sininen levy {{ this.$store.getters.getTotalAmount('bar') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('bar') < 50 && this.$store.getters.getTotalAmount('bar') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 50 kpl.
        </span>
      </p>
      <p><span class="total pt-6 is-inline-block" v-if="this.$store.getters.getTotalAmount('marianne') > 0" :class="{ red: this.$store.getters.getTotalAmount('marianne') < 50 }">Marianne kotelo {{ this.$store.getters.getTotalAmount('marianne') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('marianne') < 50 && this.$store.getters.getTotalAmount('marianne') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 50 kpl.
        </span>
      </p>
      <p><span class="total pt-6 is-inline-block" v-if="this.$store.getters.getTotalAmount('custom-candy-box') > 0" :class="{ red: this.$store.getters.getTotalAmount('custom-candy-box') < 50 }">Custom candy box kotelo {{ this.$store.getters.getTotalAmount('custom-candy-box') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('custom-candy-box') < 50 && this.$store.getters.getTotalAmount('custom-candy-box') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 50 kpl.
        </span>
      </p>
      <p><span class="total pt-6 is-inline-block" v-if="this.$store.getters.getTotalAmount('calendar-b2b') > 0" :class="{ red: this.$store.getters.getTotalAmount('calendar-b2b') < 20 }">Fazer Oma kalenteri {{ this.$store.getters.getTotalAmount('calendar-b2b') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('calendar-b2b') < 20 && this.$store.getters.getTotalAmount('calendar-b2b') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 20 kpl.
        </span>
      </p>
   </div>
    <div class="column is-2 has-text-left" v-if="getCalendarMode()">
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('calendar') == 20">
        <span class="red">
          Tilauksen maksimimäärä on 20 kpl.
        </span>
      </p>
   </div>

    <div class="column is-one-quarter has-text-right bottom-pricing-info" v-if="!getCalendarMode()">
      <p><span class="total">Yhteensä {{ total_pcs }} kpl</span></p>
    </div>
    <div class="column is-4 has-text-right bottom-pricing-info" v-else>
      <p v-for="(product, index) in products">
        <span class="total">Fazer suklaajoulukalenteri omalla kuvalla #{{index+1}}</span>
      </p>
    </div>
    <div class="column is-2 has-text-left bottom-pricing-info"  v-if="getCalendarMode()">
      <p>
        <span class="total">Yhteensä {{ total_pcs }} kpl</span>
      </p>
    </div>

    <div class="column is-offset-1 is-one-quarter has-text-right bottom-pricing-info">
      <p><span class="total">{{ total_amount }} € </span></p>
      <span class="is-inline-block vat-amount">Sisältää veroja {{ vat_amount }} € (alv. 14 %)</span>
    </div>
  </div>

  <div class="columns is-hidden-tablet bottom-pricing-info">
    <div class="column is-offset-2 is-one-quarter has-text-left" v-if="!getCalendarMode()">
      <p><span class="total" v-if="this.$store.getters.getTotalAmount('domino') > 0" :class="{ red: this.$store.getters.getTotalAmount('domino') < 24 }">Domino {{ this.$store.getters.getTotalAmount('domino') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('domino') < 24 && this.$store.getters.getTotalAmount('domino') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 24 kpl.
        </span>
      </p>
      <p><span class="total pt-6 is-inline-block" v-if="this.$store.getters.getTotalAmount('blue') > 0" :class="{ red: this.$store.getters.getTotalAmount('blue') < 20 }">Maitosuklaakonvehti {{ this.$store.getters.getTotalAmount('blue') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('blue') < 20 && this.$store.getters.getTotalAmount('blue') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 20 kpl.
        </span>
      </p>
      <p><span class="total pt-6 is-inline-block" v-if="this.$store.getters.getTotalAmount('bar') > 0" :class="{ red: this.$store.getters.getTotalAmount('bar') < 50 }">Fazer sininen levy {{ this.$store.getters.getTotalAmount('bar') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('bar') < 50 && this.$store.getters.getTotalAmount('bar') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 50 kpl.
        </span>
      </p>
      <p><span class="total pt-6 is-inline-block" v-if="this.$store.getters.getTotalAmount('marianne') > 0" :class="{ red: this.$store.getters.getTotalAmount('marianne') < 50 }">Marianne kotelo {{ this.$store.getters.getTotalAmount('marianne') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('marianne') < 50 && this.$store.getters.getTotalAmount('marianne') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 50 kpl.
        </span>
      </p>
      <p><span class="total pt-6 is-inline-block" v-if="this.$store.getters.getTotalAmount('custom-candy-box') > 0" :class="{ red: this.$store.getters.getTotalAmount('custom-candy-box') < 50 }">Custom candy box kotelo {{ this.$store.getters.getTotalAmount('custom-candy-box') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('custom-candy-box') < 50 && this.$store.getters.getTotalAmount('custom-candy-box') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 50 kpl.
        </span>
      </p>
      <p><span class="total pt-6 is-inline-block" v-if="this.$store.getters.getTotalAmount('calendar-b2b') > 0" :class="{ red: this.$store.getters.getTotalAmount('calendar-b2b') < 20 }">Fazer Oma kalenteri {{ this.$store.getters.getTotalAmount('calendar-b2b') }} kpl</span></p>
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('calendar-b2b') < 20 && this.$store.getters.getTotalAmount('calendar-b2b') > 0">
        <span class="red">
          Minimitilausmäärä ei ole täynnä. Pienin tuotekohtainen tilausmäärä on yhteensä 20 kpl.
        </span>
      </p>
    </div>
    <div class="column is-offset-2 is-one-quarter has-text-left" v-if="getCalendarMode()">
      <p class="mt-3" style="line-height: 0.8" v-if="this.$store.getters.getTotalAmount('calendar') == 20">
        <span class="red">
          Tilauksen maksimimäärä on 20 kpl.
        </span>
      </p>
    </div>
  </div>

  <div class="columns is-hidden-tablet is-mobile">
    <div class="column is-6 has-text-center is-size-5">
      <span class="total">{{ total_pcs }} kpl</span>
    </div>

    <div class="column is-6 has-text-right is-size-5">
      <span class="total">{{ total_amount }} € </span>
      <span class="is-inline-block vat-amount">Sisältää veroja {{ vat_amount }} € (alv. 14 %)</span>
    </div>
  </div>
</template>

<script>
import productAmount from '@/components/shipment/productAmount'
import asModalDialog from '@/components/helpers/asModalDialog'
import ThreeDPreview from '@/components/3d/ThreeDPreview'
import canvasImage from '@/components/canvasImage'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import ThreeDPreviewMultipleObjects from '@/components/3d/ThreeDPreviewMultipleObjects'
import CustomCandyBoxCanvas from '@/components/CustomCandyBoxCanvas.vue'
import MarianneCanvas from '@/components/MarianneCanvas.vue'
import deliveryAddressModal from '@/components/modals/deliveryAddressModal'

let fontMapping = {
  F_GOTHAM: ['Gotham Black', '22px', '30px'],
  F_TARTINE: ['Tartine Script Black', '24px', '30px'],
  F_PARAHO: ['Praho Pro', '24px', '30px'],
  F_MYRIADPRO: ['myriadpro-cond', '24px', '30px'],
  F_HELVETICA: ['helvetica-neue', '30px', '30px'],
}

export default {
  name: 'productList',
  components: {
    productAmount,
    asModalDialog,
    ThreeDPreviewMultipleObjects,
    canvasImage,
    ThreeDPreview,
    CustomCandyBoxCanvas,
    deliveryAddressModal,
    MarianneCanvas
  },
  props: {
    isEditable: {
      type: Boolean,
      default: true,
    },
    isDiscountEditable: {
      type: Boolean,
      default: true,
    },
    orderDeliveryType: {
      type: String,
      default: 'ALL_TO_SAME_ADDRESS'
    }
  },
  data() {
    return {
      fazerGblPath: `${process.env.BASE_URL}3d/fazer-candy-box-v2.glb`,
      dominoGblPath: `${process.env.BASE_URL}3d/domino3.glb`,
      publicPath: process.env.BASE_URL,
      fazerPreviewWidth: 1085,
      fazerPreviewHeight: 1082,
      dominoPreviewWidth: 1280,
      dominoPreviewHeight: 960,
      mariannePreviewWidth: 1280,
      mariannePreviewHeight: 960,
      preview: {
        gblPath: '',
        textures: [],
        gblVariable: '',
        textureWidth: 0,
        textureHeight: 0,
        domino: {
          image_croppied: '',
          isLandscape: false,
          textAsImageUrl: '',
          logoTexture: '',
          imageTexture: ''
        },
        fazer: {
          frontAsImageUrl: '',
          backAsImageUrl: '',
          sideAsImageUrl: ''
        },
        marianne: {
          frontAsImageUrl: '',
          backAsImageUrl: ''
        },
        candybox: {
          frontAsImageUrl: '',
          backAsImageUrl: ''
        }
      },
      threeDdataMarianne: {
        name: 'Marianne',
        uri: `${process.env.BASE_URL}3d/marianne.glb`,
        objects: [],
        animations: ['FullyOpen', 'PartiallyOpen', 'Closed']
      },
      threeDdataCandyBox: {
        name: 'Candybox',
        uri: `${process.env.BASE_URL}3d/candybox.glb`,
        objects: [],
        animations: ['FullyOpen', 'PartiallyOpen', 'Closed']
      },
      frontImageUrl: '',
      backImageUrl: '',
      previewId: null,
      custom_candy_box_url: '',
      custom_candy_box_color: 'red',
      currentProduct: '',
      internationalDelivery: false
    }
  },
  methods: {
    ...mapActions({
      partialUpdateOrder: 'partialUpdateOrder',
      removeBox: 'removeBox',
      removeMarianneBox: 'removeMarianneBox',
      removeBlueBox: 'removeBlueBox',
      removeBlueBar: 'removeBlueBar',
      removeCandyBox: 'removeCandyBox',
      removeCalendar: 'removeCalendar',
      updateOrder: 'updateOrder',
      cloneDominoBox: 'cloneDominoBox',
      cloneFazerBox: 'cloneFazerBox',
      cloneFazerBar: 'cloneFazerBar',
      cloneMarianneBox: 'cloneMarianneBox',
      cloneCandyBox: 'cloneCandyBox',
      cloneCalendarB2B: 'cloneCalendarB2B'
    }),
    ...mapGetters({
      getOrderData: 'getOrderData',
      getSavedItems: 'getSavedItems',
      getFreightPricePerWeight: 'getFreightPricePerWeight',
      getCalendarMode: 'getCalendarMode',
    }),
    ...mapMutations([
      'setSpinnerState',
      'setCartSpinnerState',
      'updateProductAddress',
      'setNotificationActiveState'
    ]),
    removeDiscount() {
      this.$store.commit('clearVoucherDiscount')
      this.partialUpdateOrder({
         discount_code: null,
      })
    },
    previewLogoCss(logo_url) {
      let css
      if (logo_url != null) {
        css = {
          'background-image': `url(${logo_url})`,
        }
      } else {
        css = {
          display: 'none',
        }
      }
      return css
    },
    updateProductDeliveryType(productId, deliveryType) {
      let product = this.products.find((product) => product.id == productId)
      if (product.title == '') {
        product.csv_status = 'NEED_CSV'
      } else {
        if (deliveryType == 'FINLAND_MAILBOX' || deliveryType == 'INTERNATIONAL_MAILBOX') {
          product.csv_status = 'NEED_CSV'
        } else {
          product.csv_status = 'NO_CSV_NEEDED'
        }
      }
    },
    openPreview(productId) {
      let self = this
      this.previewId = productId
      let productObj = self.products.find((product) => product.id === productId)
      if(productObj) {
        if (productObj.product.name == 'domino') {
          self.preview.domino.image_croppied = productObj.image.image_crop
          self.preview.domino.isLandscape = productObj.orientation === 'OL' ? true : false
          self.preview.domino.textAsImageUrl = {
            uri: productObj.image.text_image_64,
            height: 320,
            width: 1280,
            rotation: 0,
          }
          self.preview.domino.imageTexture = {
            uri: productObj.image.image_crop,
            height: 640,
            width: 1280,
            rotation: productObj.orientation === 'OL' ? 0 : 90,
          }
          if (productObj.croppied_logo_url == null) {
            self.preview.domino.logoTexture = {}
          }
          else if (productObj.orientation === 'OL') {
            self.preview.domino.logoTexture = {
              uri: productObj.croppied_logo_url,
              height: 180,
              width: 180,
              rotation: 0,
              offsetX: 1090,
              offsetY: 750,
            }
          } else {
            self.preview.domino.logoTexture = {
              uri: productObj.croppied_logo_url,
              height: 180,
              width: 180,
              rotation: 90,
              offsetX: 1090,
              offsetY: -330,
            }
          }
          self.preview.domino.logoTexture = {
            uri: productObj.croppied_logo_url
          }
          if (self.preview.domino.logoTexture.uri == "") {
            self.preview.textures = [self.preview.domino.textAsImageUrl, self.preview.domino.imageTexture]
          } else {
            self.preview.textures = [self.preview.domino.textAsImageUrl, self.preview.domino.imageTexture, self.preview.domino.logoTexture]
          }
          self.preview.gblPath = self.dominoGblPath
          self.preview.gblVariable = 'frontFace'
          self.preview.textureWidth = self.dominoPreviewWidth
          self.preview.textureHeight = self.dominoPreviewHeight
          return self.$refs.previewModal.showModal()
        } else if(productObj.product.name == 'marianne')  {
          self.setSpinnerState(true)
          self.$nextTick(() => {
            self.loadTexturesMarianne()
                .then(() => {
                  self.setSpinnerState(false)
                  return self.$refs.mariannePreview.showModal()
                })
                .then((amount, ...args) => {
                  console.log('3d preview closed', args)
                })
                .catch((error) => {
                  console.log('3d preview closed', error)
                })
          })
        } else if(productObj.product.name == 'custom-candy-box')  {
          self.setSpinnerState(true)
          self.$nextTick(() => {
            self.loadTexturesCandyBox()
                .then(() => {
                  self.setSpinnerState(false)
                  return self.$refs.customCandyBoxPreview.showModal()
                })
                .then((amount, ...args) => {
                  console.log('3d preview closed', args)
                })
                .catch((error) => {
                  console.log('3d preview closed', error)
                })
          })
        } else if(productObj.product.name == 'blue') {
          self.preview.fazer.frontAsImageUrl = {
            uri: productObj.front_side_picture_url,
            height: 435,
            width: 1085,
            rotation: 0,
          }
          self.preview.fazer.backAsImageUrl = {
            uri: productObj.back_side_picture_url,
            height: 435,
            width: 1280,
            rotation: 0,
          }
          self.preview.fazer.sideAsImageUrl = {
            uri: productObj.side_side_picture_url,
            height: 212,
            width: 1085,
            rotation: 0,
          }
          self.preview.gblPath = self.fazerGblPath
          self.preview.textures = [self.preview.fazer.backAsImageUrl, self.preview.fazer.sideAsImageUrl, self.preview.fazer.frontAsImageUrl]
          self.preview.gblVariable = 'Sleeve'
          self.preview.textureWidth = self.fazerPreviewWidth
          self.preview.textureHeight = self.fazerPreviewHeight
          return self.$refs.previewModal.showModal()
        } else if(productObj.product.name == 'bar') {
          let back_picture = productObj.product.json_data.back_texture
          if (productObj.back_side_picture_url != '') {
            back_picture = productObj.back_side_picture_url
          }
          let front_picture = productObj.product.json_data.front_texture
          if (productObj.front_side_picture_url != '') {
            front_picture = productObj.front_side_picture_url
          }
          let textTexture = {
            uri: back_picture,
            height: 399,
            width: 1024,
            rotation: 0,
          }
          let coverTexture = {
            uri: front_picture,
            height: 399,
            width: 1024,
            rotation: 0,
          }
          this.$refs.smallSideCanvas.getImageAsDataUrl().then(function (dataUrl) {
            let smallSideAsImageUrl = dataUrl
            self.$refs.bigSideCanvas.getImageAsDataUrl().then(function (dataUrl) {
              let bigSideAsImageUrl = dataUrl
              let smallSideImageTexture = {
                uri: smallSideAsImageUrl,
                height: 61,
                width: 1024,
                rotation: 0,
              }
              let bigSidebackImageTexture = {
                uri: bigSideAsImageUrl,
                height: 300,
                width: 1024,
                rotation: 0,
              }
              self.preview.gblPath = `${process.env.BASE_URL}3d/fazer-milk-chocolate-bar-v3.glb`
              self.preview.textures = [bigSidebackImageTexture, coverTexture, smallSideImageTexture, textTexture]
              self.preview.gblVariable = 'Overlay'
              self.preview.textureWidth = 1024
              self.preview.textureHeight = 1167
              return self.$refs.previewModal.showModal()
            })
          })
        }
      }
    },
    deleteProduct(product) {
      let self = this
      this.$refs.deleteProduct.showModal()
         .then(() => {
           if (product.product.name == "domino") {
             this.removeBox(product.id).then(() => {
               if (self.getSavedItems().length == 0) {
                 this.$router.push({name: 'Home'})
               }
             })
           } else if(product.product.name == "marianne") {
             this.removeMarianneBox(product.id).then(() => {
               if (self.getSavedItems().length == 0) {
                 this.$router.push({name: 'Home'})
               }
             })
           } else if (product.product.name == "blue") {
             this.removeBlueBox(product.id) .then(() => {
               if (self.getSavedItems().length == 0) {
                 this.$router.push({name: 'Home'})
               }
             })
           } else if (product.product.name == "bar"){
             this.removeBlueBar(product.id).then(() => {
               if (self.getSavedItems().length == 0) {
                 this.$router.push({name: 'Home'})
               }
             })
            } else if (product.product.name == "calendar"){
             this.removeCalendar(product.id).then(() => {
               if (self.getSavedItems().length == 0) {
                 this.$router.push({name: 'Home'})
               }
             })
            } else if (product.product.name == "calendar-b2b"){
             this.removeCalendar(product.id).then(() => {
               if (self.getSavedItems().length == 0) {
                 this.$router.push({name: 'Home'})
               }
             })
            } else {
             this.removeCandyBox(product.id).then(() => {
               if (self.getSavedItems().length == 0) {
                 this.$router.push({name: 'Home'})
               }
             })
            }
         })
         .catch(() => {
           console.log('')
         })
    },
    cloneProduct(product) {
      this.setNotificationActiveState({
        'notificationState': true,
        'message': "Tuote on kopioitu ja lisätty ostoskoriin. Voit muokata tarvittaessa määrää ja toimitustapaa."
      })
      product['draft'] = false
      this.setSpinnerState(true)
      this.setCartSpinnerState(true)
      let self = this
      this.updateOrder({'products': this.products}).then(() => {
        self.cloning = true
        self.getCloneFunction(product)
      })
    },
    editProduct(product) {
      this.setSpinnerState(true)
      this.updateOrder({'products': this.products}).then(() => {
        // if thumbnail is empty it means it didnt go through the
        if (product.product.name == 'custom-candy-box' || product.product.name == 'bar' || product.product.name == 'marianne') {
          this.$router.push(
            {
              name: 'editor-tab', params:
                  {
                    uuid: product.id,
                    product: product.product.name
                  }
            }
          )
        } else {
          this.$router.push(
              {
                name: 'editor', params:
                    {
                      uuid: product.id,
                      product: product.product.name
                    }
              }
          )
        }
        this.setSpinnerState(false)
      })
    },
    get_freight_price(weight) {
      let freights = this.getFreightPricePerWeight().slice(0)
      freights = freights.reverse()
      if (freights.length == 0) {
        return 0
      }
      let valid_freight = freights[0]
      for (let i = 0; i < freights.length; i++) {
        if (weight < freights[i].weight_range) {
          valid_freight = freights[i]
        }
      }
      return valid_freight.base_price + (valid_freight.price * weight)
    },
    async loadTexturesCandyBox() {
      let self = this
      return self.$refs.customCandyBoxCanvas.draw().then(() => {
        self.preview.candybox.backAsImageUrl = self.$refs.customCandyBoxCanvas.$refs.frontCanvas.toDataURL('image/jpeg', 0.2)
        self.preview.candybox.frontAsImageUrl = self.$refs.customCandyBoxCanvas.$refs.backCanvas.toDataURL('image/jpeg', 0.2)
      })
    },
    async loadTexturesMarianne() {
      let self = this
      return self.$refs.marianneCanvas.draw().then(() => {
        self.preview.marianne.backAsImageUrl = self.$refs.marianneCanvas.$refs.frontCanvas.toDataURL('image/jpeg', 0.2)
        self.preview.marianne.frontAsImageUrl = self.$refs.marianneCanvas.$refs.backCanvas.toDataURL('image/jpeg', 0.2)
      })
    },
    openAddressModal(product) {
      if (product.address_type == 'INTERNATIONAL_FREIGHT' || product.address_type == 'INTERNATIONAL_MAILBOX') {
        this.internationalDelivery = true
      } else {
        this.internationalDelivery = false
      }
      this.currentProduct = product.id
      this.$refs.addressModal.showModal(this.currentProduct)
    },
    saveAddressLineItem(product) {
      product.mailbox_selected = true
      this.setSpinnerState(true)
      this.setCartSpinnerState(true)
      let self = this
      this.updateOrder({'products': this.products}).then(() => {
        self.setSpinnerState(false)
        self.setCartSpinnerState(false)
      })
    },
    saveProductAddress() {
      this.setSpinnerState(true)
      this.setCartSpinnerState(true)
      let self = this
      this.updateOrder({'products': this.products}).then(() => {
        self.setSpinnerState(false)
        self.setCartSpinnerState(false)
      })
    },
    getCloneFunction(product) {
      let name = product.product.name
      if (name == 'domino') {
        this.cloneDominoBox(product).then(() => {
        this.setSpinnerState(false)
        this.setCartSpinnerState(false)
        this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
      })
      } else if (name == 'blue') {
        this.cloneFazerBox(product).then(() => {
        this.setSpinnerState(false)
        this.setCartSpinnerState(false)
        this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
      })
      } else if (name == 'bar') {
        this.cloneFazerBar(product).then(() => {
        this.setSpinnerState(false)
        this.setCartSpinnerState(false)
        this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
      })
      } else if (name == 'marianne') {
        this.cloneMarianneBox(product).then(() => {
        this.setSpinnerState(false)
        this.setCartSpinnerState(false)
        this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
      })
      } else if (name == 'custom-candy-box') {
        this.cloneCandyBox(product).then(() => {
        this.setSpinnerState(false)
        this.setCartSpinnerState(false)
        this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
      })
      } else if (name == 'calendar-b2b') {
        this.cloneCalendarB2B(product).then(() => {
        this.setSpinnerState(false)
        this.setCartSpinnerState(false)
        this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
      })
      }
    },
    addressParser(product) {
      let company = product.delivery_address.recv_company
      if (company != '') {
        company = company + ', '
      }
      return product.delivery_address.recv_name + ', ' + company + product.delivery_address.recv_street
      + ', ' + product.delivery_address.recv_zip  + ', ' + product.delivery_address.recv_town + ', ' + product.delivery_address.recv_phone      + ', ' + product.delivery_address.recv_country

    },
    getTotalMailboxPriceForOneProduct(product) {
      let total = product.amount
      let result = parseFloat(total*1.14)
      return result.toFixed(2)
    },
    getTotalFreightPriceForOneAddress(product) {
      // check how many products are using the same address
      let weight = this.getWeightForAddress(product)
      let total = this.get_freight_price(weight)
      let result = parseFloat(total*1.14)
      let price = result.toFixed(2)
      return parseFloat(price).toFixed(2)
    },
    getWeightForAddress(product) {
      if (product.delivery_address != null) {
        let address_id = product.delivery_address.id
        let weight = 0
        for (let i = 0; i < this.getSavedItems().length; i++) {
          if (this.getOrderDeliveryType == 'MIXED' && (this.getSavedItems()[i].address_type == 'FINLAND_FREIGHT' || this.getSavedItems()[i].address_type == 'INTERNATIONAL_FREIGHT') && this.getSavedItems()[i].delivery_address != null && this.getSavedItems()[i].delivery_address.id == address_id) {
            weight += this.getSavedItems()[i].product.weight * this.getSavedItems()[i].amount
          }
        }
        return weight.toFixed(2)
      }
      return 0
    },
  },
  computed: {
    products() {
      if (this.getSavedItems()) return this.getSavedItems()
      else return []
    },
    threeDdataSetMarianne() {
      return {...this.threeDdataMarianne, objects: [this.textImageTexture, this.backImageTexture]}
    },
    threeDdataSetCandyBox() {
      return {...this.threeDdataCandyBox, objects: [this.textImageTextureCandyBox, this.backImageTextureCandyBox]}
    },
    textImageTextureCandyBox() {
      return {
        id: 1,
        objectName: 'bottom',
        name: 'Bottom',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.preview.candybox.frontAsImageUrl,
            width: 1257,
            height: 2118,
            rotation: 0,
          },
        ],
      }
    },
    backImageTextureCandyBox() {
      return {
        id: 2,
        name: 'Top',
        objectName: 'top',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.preview.candybox.backAsImageUrl,
            width: 1257,
            height: 2118,
            rotation: 0,
          }
        ],
      }
    },
    textImageTexture() {
      return {
        id: 1,
        objectName: 'bottom',
        name: 'Bottom',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.preview.marianne.frontAsImageUrl,
            width: 1257,
            height: 2118,
            rotation: 0,
          },
        ],
      }
    },
    backImageTexture() {
      return {
        id: 2,
        name: 'Top',
        objectName: 'top',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.preview.marianne.backAsImageUrl,
            width: 1257,
            height: 2118,
            rotation: 0,
          }
        ],
      }
    },
    total_pcs() {
      let total_pcs = 0
      this.products.forEach((product) => {
        total_pcs += product.amount
      })
      return total_pcs
    },
    getTotalWeight() {
      let weight = 0
      for (let i = 0; i < this.getSavedItems().length; i++) {
        if (this.getOrderDeliveryType == 'ALL_TO_SAME_ADDRESS' ||  (this.getOrderDeliveryType == 'MIXED' && this.getSavedItems()[i].address_type == 'FINLAND_FREIGHT')) {
          weight += this.getSavedItems()[i].product.weight * this.getSavedItems()[i].amount
        }
      }
      return weight.toFixed(2)
    },
    getTotalFreightPrice() {
      if (this.getCalendarMode()) {
        return parseFloat(4.90).toFixed(2)
      }
      // if its option all to mailbox then it has no sense to calculate this
      if (this.getOrderDeliveryType != 'MIXED' && this.getOrderDeliveryType != 'ALL_TO_SAME_ADDRESS') {
        return 0
      }
      let price = 0
      if (this.getOrderDeliveryType == 'MIXED') { // on mixed we calculate the freight cost per address
        for (let i = 0; i < this.getSavedItems().length; i++) {
          // check how many products are using the same address
          if (this.getSavedItems()[i].address_type == 'FINLAND_FREIGHT') {
            let weight = this.getWeightForAddress(this.getSavedItems()[i])
            let total = this.get_freight_price(weight)
            let result = parseFloat(total * 1.14)
            price = parseFloat(result.toFixed(2)) + price
          }
        }
      } else if (this.getOrderDeliveryType == 'ALL_TO_SAME_ADDRESS') { // all the products in the same freight
        let weight = this.getTotalWeight
        if (weight == 0) {
          return 0
        } else {
          let total = this.get_freight_price(weight)
          let result = parseFloat(total*1.14)
          price = result.toFixed(2)
        }
      }
      return parseFloat(price).toFixed(2)
    },
    getTotalMailboxItemsFinland() {
      let total = 0
      for (let i = 0; i < this.getSavedItems().length; i++) {
        if (this.getOrderDeliveryType == 'ALL_TO_MULTIPLE_ADDRESS' || (this.getOrderDeliveryType == 'MIXED' && (this.getSavedItems()[i].address_type == 'FINLAND_MAILBOX'))) {
          total += this.getSavedItems()[i].amount
        }
      }
      return total
    },
    getTotalMailboxItemsInternational() {
      let total = 0
      for (let i = 0; i < this.getSavedItems().length; i++) {
        if (this.getOrderDeliveryType == 'MIXED' && this.getSavedItems()[i].address_type == 'INTERNATIONAL_MAILBOX') {
          total += this.getSavedItems()[i].amount
        }
      }
      return total
    },
    getTotalFreightItemsInternational() {
      let total = 0
      for (let i = 0; i < this.getSavedItems().length; i++) {
        if (this.getOrderDeliveryType == 'MIXED' && this.getSavedItems()[i].address_type == 'INTERNATIONAL_FREIGHT') {
          total += this.getSavedItems()[i].amount
        }
      }
      return total
    },
    getTotalMailboxPrice() {
      let total = this.getTotalMailboxItemsFinland * this.mailbox_price
      let result = parseFloat(total*1.14)
      return result.toFixed(2)
    },
    discounts() {
      return this.$store.getters.getVoucherDiscount
    },
    mailbox_price() {
      return this.$store.getters.getShippingPrice.price
    },
    total_amount() {
      let amount = parseFloat(this.$store.getters.getTotalAmountProducts.toFixed(2))
      amount += parseFloat(this.getTotalMailboxPrice) + parseFloat(this.getTotalFreightPrice)
      let discount_value = this.$store.getters.getVoucherDiscount
      if (discount_value) {
        if (discount_value.amount_off != null && discount_value.amount_off != '') {
          amount -= discount_value.amount_off * 0.01
          amount = parseFloat(amount.toFixed(2))
        } else {
          amount -= (amount * discount_value.percent_off) / 100
          amount = parseFloat(amount.toFixed(2))
        }
      }

      return amount.toFixed(2)
    },
    vat_amount() {
      let vat = this.total_amount - (this.total_amount / 1.14)
      return vat.toFixed(2)
    },
    discount_value() {
      if (this.discounts != null) {
        if (this.discounts.amount_off != undefined) {
          return this.discounts.amount_off * 0.01 + ' €'
        } else {
          return this.discounts.percent_off + ' %'
        }
      }
      return ''
    },
    getCandyBoxText() {
      if (this.previewId == null) {
        return ''
      }
      let productObj = this.products.find((product) => product.id === this.previewId)
      if (productObj.product.name != 'custom-candy-box') {
        return ''
      }
      return productObj.text
    },
    getCandyBoxSignature() {
      if (this.previewId == null) {
        return ''
      }
      let productObj = this.products.find((product) => product.id === this.previewId)
      if (productObj.product.name != 'custom-candy-box') {
        return ''
      }
      return productObj.signature
    },
    getCandyBoxTitle() {
      if (this.previewId == null) {
        return ''
      }
      let productObj = this.products.find((product) => product.id === this.previewId)
      if (productObj.product.name != 'custom-candy-box') {
        return ''
      }
      return productObj.title
    },
    getCandyBoxLogo() {
      if (this.previewId == null) {
        return ''
      }
      let productObj = this.products.find((product) => product.id === this.previewId)
      if (productObj.product.name != 'custom-candy-box') {
        return ''
      }
      return productObj.croppied_logo_url
    },
    getCandyBoxFont() {
      if (this.previewId == null) {
        return ''
      }
      let productObj = this.products.find((product) => product.id === this.previewId)
      if (productObj.product.name != 'custom-candy-box') {
        return ''
      }
      return fontMapping[productObj.font][0]
    },
    getCandyBoxColor() {
      if (this.previewId == null) {
        return ''
      }
      let productObj = this.products.find((product) => product.id === this.previewId)
      if (productObj.product.name != 'custom-candy-box') {
        return ''
      }
      return productObj.background_color
    },
    hasDominoOrKfProduct() {
      for (let i = 0; i < this.products.length; i++) {
        if(!this.products[i].draft) {
          if (this.products[i].product.name == 'domino' || this.products[i].product.name == 'blue' || this.products[i].product.name == 'calendar-b2b') {
            return true
          }
        }
      }
      return false
    },
    hasCalendarProduct() {
      for (let i = 0; i < this.products.length; i++) {
        if(!this.products[i].draft) {
          if (this.products[i].product.name == 'calendar') {
            return true
          }
        }
      }
      return false
    },
    productsWithAddresses() {
      const uuids = new Set()
      // select the products with address
      let tmpProducts = this.products.filter(function( product ) {
        return product.delivery_address !== undefined;
      })

      // remove duplicated addresses (several products pointing to the same address)
      return tmpProducts.filter(product => {
        if (product.delivery_address != null) {
          if (uuids.has(product.delivery_address.id)) {
            return false
          } else {
            uuids.add(product.delivery_address.id);
            return true
          }
        }
      })
    },
    getOrderDeliveryType() {
      // on cart page is a local data variable, on shipment/payment page this comes from the order model
      if (this.$route.name == 'cart') {
        return this.orderDeliveryType
      } else {
        return this.getOrderData().delivery_type
      }
    },
    hasProductsWithoutAddress() {
      for (let i = 0; i < this.products.length; i++) {
        if(!this.products[i].draft) {
          if (this.products[i].address_type == null ||
              ((this.products[i].address_type == 'INTERNATIONAL_FREIGHT' || this.products[i].address_type == 'FINLAND_FREIGHT') && this.products[i].delivery_address == undefined) ||
              (this.products[i].address_type == 'INTERNATIONAL_FREIGHT' && this.products[i].delivery_address.recv_country == 'Suomi') ||
              (this.products[i].address_type == 'FINLAND_FREIGHT' && this.products[i].delivery_address.recv_country != 'Suomi')
          ) {
            return true
          }
        }
      }
      return false
    },
    hasProductsWithoutMailboxSelected() {
      for (let i = 0; i < this.products.length; i++) {
        if(!this.products[i].draft) {
          if ((this.products[i].address_type == 'INTERNATIONAL_MAILBOX' || this.products[i].address_type == 'FINLAND_MAILBOX') && !this.products[i].mailbox_selected) {
            return true
          }
        }
      }
      return false
    },
    hasInternationalDeliveries() {
      for (let i = 0; i < this.products.length; i++) {
        if(!this.products[i].draft) {
          if ((this.products[i].address_type == 'INTERNATIONAL_FREIGHT' || this.products[i].address_type == 'INTERNATIONAL_MAILBOX')) {
            return true
          }
        }
      }
      return false
    },
    getMarianneTitle() {
      if (this.previewId == null) {
        return ''
      }
      let productObj = this.products.find((product) => product.id === this.previewId)
      if (productObj.product.name != 'marianne') {
        return ''
      }

      if (productObj.front_side_picture_url == '' && productObj.back_side_picture_url == '') {
        return 'Malliteksti'
      }
      return productObj.title
    },
    getMarianneText() {
      if (this.previewId == null) {
        return ''
      }
      let productObj = this.products.find((product) => product.id === this.previewId)
      if (productObj.product.name != 'marianne') {
        return ''
      }

      if (productObj.front_side_picture_url == '' && productObj.back_side_picture_url == '') {
        return 'Tämä on malliteksti, jonka tilalle tulee haluamasi​\n' +
            '\n' +
            'teksti.  Merkkejä voi olla enintään 300.​\n' +
            '\n' +
            'Tekstiä voi olla enintään 6 riviä.'
      }
      return productObj.text
    },
    getMarianneSignature() {
      if (this.previewId == null) {
        return ''
      }
      let productObj = this.products.find((product) => product.id === this.previewId)
      if (productObj.product.name != 'marianne') {
        return ''
      }

      if (productObj.front_side_picture_url == '' && productObj.back_side_picture_url == '') {
        return 'Allekirjoitus'
      }
      return productObj.signature
    },
  },
}
</script>

<style scoped lang="scss">
.grey-box {
  background-color: #F2F1F0;
}

.m-auto {
  margin: auto;
}

span {
  font-size: 1.125rem;
}

.total {
  font-size: 20px;
}

.border-top-grey {
  border-top: 3px solid #f2f1f0;
}

.border-bottom-grey {
  border-bottom: 3px solid #f2f1f0;
}

.svg-wrapper {
  svg:hover {
    cursor: pointer;
    color: #F4ACBC;
  }
}

.text-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-wrapper {
  position: relative;
  width: 100%;
  .table-wrapper-marianne1 {
    top: 50%;
    font-size: 25px;
    left: -3%;
  }
  .table-wrapper-marianne2 {
    top: 37%;
    font-size: 14px;
    left: 14%;
  }
  .table-wrapper-marianne3 {
    top: 78%;
    font-size: 14px;
    left: 14%;
  }
  .table-wrapper-marianne {
    position: absolute;
    transform: translate(50%, -50%);
    color: #F0E1A0;
    z-index: 2;
    font-weight: 500;
    font-family: 'Felt That';
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.box-text {
  white-space: pre-wrap;
}

.vat-amount {
  font-size: 1rem;
}

.box-text-wrapper {
  text-align: center;
}

.bottom-pricing-info {
  p {
    span {
      font-size: 15px;
      font-family: gill-sans-nova;
      font-weight: bold;
    }
    span.red {
      color: red !important;
    }
  }
}

.mobile-price-summary-text {
  font-size: 14px;
}

p.position {
  font-size: 1.025rem;
  height: 125px;
  line-height: 125px;
  @media screen and (max-width: 1215px) {
    font-size: 12px;
    height: 89px;
    line-height: 89px;
  }
}

.mobile-icons {
  span {
    margin-right: 10px;
    margin-left: 5px;
    svg {
      font-size: 30px;
    }
  }
}
.products-list {
  .column {
    @media (min-width: 768px) {
      height: 118px;
      line-height: 118px;
    }
  }
  .column.vertical-thumbnail-column {
    height: 150px;
    @media (max-width: 768px) {
      figure {
        width: 66px;
      }
      img {
        height: 100px;
        width: auto;
      }
    }
  }
}

figure.domino-thumbnail-picture {
  img {
    width: auto;
    max-height: 80px;
    width: 200px;
    object-fit: cover;
  }
}

figure.domino-thumbnail-text {
  img {
    width: 100%;
    display: inline-block;
    height: 80px;
    line-height: 80px;
  }
}

.special-delivery-icon {
  color: #15256B;
  font-size: 1.6rem;
}

.special-delivery-text {
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  color: #000000;
  font-weight: bold;
}

.column-h-reset {
  height: 33px !important;
}

.mobile-products {

}

.marianne-thumbnail {
  img {
    height: 90px;
    width: auto;
    margin-left: 0;
  }
}

.add-address-button {
  background-color: white;
  border: 2px solid #15256B;
  color: #15256B;
  width: 220px;
}

input[type="radio"] {
  display: none;
}

.graphical-radio {
  background: white;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #15256B;
  margin-right: 6px;
  width: 25px;
  height: 25px;
  position: relative;
  top: 4px;
  @media (max-width: 768px) {
    width: 15px;
    height: 15px;
  }
}

input[type="radio"]:checked + .graphical-radio {
  background: #15256B;
}

label.add-address {
  font-size: 20px;
  padding-bottom: 5px;
  display: inline-block;
  color: black;
  line-height: 34.5px;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 20.5px;
  }
}

.add-address-column {
  height: auto !important;
  //line-height: initial !important;
  line-height: 10px !important;
  @media (max-width: 768px) {
    padding-top: 0px !important;
  }
}

.special-delivery-form {
  h1 {
    font-size: 21px;
    margin-bottom: 0px;
  }
  p {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
}

.product-address {
  display: block;
  color: #62636F;
  font-size: 18px;
  @media (max-width: 768px) {
    margin: 0 0 0 0 !important;
    span {
      font-size: 12px !important;
    }
  }
  span.edit-address {
    text-decoration: underline;
  }
  span.edit-address:hover {
    cursor: pointer;
  }
}

.button.is-primary[disabled] {
  color: white;
}

p.delivery-order-item-price {
  line-height: 15px;
  text-align: right;
  font-size: 1.125rem;
}

</style>
