<template>
  <div class="popover-content" :class="{ 'is-calendar': getCalendarMode()}">
    <div v-for="(product, index) in getSavedItems()" :key="product.id" class="columns is-size-6 is-vcentered is-mobile" v-bind:class="{ 'grey-box': index % 2 == 0 }" :class="{'mb-5': product.product.name == 'custom-candy-box' }">
      <div v-if="product.product.name == 'domino' && product.orientation == 'OL'" class="column is-3 has-text-centered">
        <figure class="image is-2by1 is-thumbnail">
          <img :src="product.image.horizontal_thumbnail_url" />
          <div class="cart-logo-small landscape" :style="previewLogoCss(product.croppied_logo_url)"></div>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'domino' && product.orientation == 'OP'" class="column is-3 has-text-centered">
        <div class="columns is-mobile">
          <div class="column is-6 is-offset-3">
            <figure class="image is-1by2">
              <img :src="product.image.vertical_thumbnail_url"/>
              <div class="cart-logo-small portrait" :style="previewLogoCss(product.croppied_logo_url)"></div>
            </figure>
          </div>
        </div>
      </div>
      <div v-else-if="product.product.name == 'marianne'" class="column is-3">
        <figure class="image is-2by1 marianne-thumbnail">
          <img v-if="product.thumbnail_url != ''" :src="product.thumbnail_url" style="background-color: #15256B;"/>
          <img v-else :src="`${publicPath}imgs/default-marianne-cover.png`" style="background-color: #15256B;"/>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'calendar'" class="column is-5">
        <figure class="image is-2by1 marianne-thumbnail">
          <img :src="product.image.thumbnail_url" style="height: 70px; width: auto; max-height: none;"/>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'calendar-b2b'" class="column is-5">
        <figure class="image is-2by1 marianne-thumbnail">
          <img :src="product.image.thumbnail_url" style="height: 70px; width: auto; max-height: none;"/>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'custom-candy-box'" class="column is-3">
        <figure class="image is-2by1 marianne-thumbnail">
          <img v-if="product.back_thumbnail_url != ''" :src="product.back_thumbnail_url" style="border-left: 15px solid; border-right: 15px solid; border-bottom: 15px solid; box-sizing: content-box; height: 62px;"
          :style="{borderColor: product.background_color}" />
          <img v-else :src="`${publicPath}imgs/default-marianne-cover.png`" style="background-color: #15256B;"/>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'bar'" class="column is-3 has-text-centered">
        <figure class="image is-2by1">
          <img v-if="product.thumbnail_url != ''" :src="product.thumbnail_url" style="background-color: #15256B;"/>
          <img v-else :src="product.product.json_data.front_thumbnail" style="background-color: #15256B;"/>
        </figure>
      </div>
      <div v-else class="column is-3 has-text-centered">
        <figure class="image is-2by1">
          <img v-if="product.thumbnail_url != ''" :src="product.thumbnail_url" style="background-color: #15256B;"/>
          <img v-else :src="`${publicPath}imgs/default-blue.png`" style="background-color: #15256B;"/>
          <div class="cart-logo-small portrait" :style="previewLogoCss(product.croppied_logo_url)"></div>
        </figure>
      </div>
      <div v-if="product.product.name == 'domino'" class="column is-3">
        <div class="text-wrapper box-text-wrapper">
         <figure class="image is-2by1">
           <img v-if="product.image.text_image_thumbnail_url != ''" :src="product.image.text_image_thumbnail_url"/>
           <img v-else :src="`${publicPath}imgs/default-domino.png`"/>
         </figure>
        </div>
      </div>
      <div v-else-if="product.product.name == 'marianne'" class="column is-3">
        <figure class="image is-2by1 marianne-thumbnail">
          <img v-if="product.back_thumbnail_url != ''" :src="product.back_thumbnail_url" style="background-color: #15256B;"/>
          <img v-else :src="`${publicPath}imgs/default-marianne-card.png`" style="background-color: #15256B"/>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'custom-candy-box'" class="column is-3">
        <figure class="image is-2by1 marianne-thumbnail">
          <img v-if="product.thumbnail_url != ''" :src="product.thumbnail_url" style="border-left: 15px solid; border-right: 15px solid; border-bottom: 15px solid; border-top: 15px solid; box-sizing: content-box;"
          :style="{borderColor: product.background_color}" />
          <img v-else :src="`${publicPath}imgs/default-marianne-card.png`" style="background-color: #15256B"/>
        </figure>
      </div>
      <div v-else-if="product.product.name == 'bar'" class="column is-3">
        <figure class="image is-2by1">
          <img v-if="product.back_thumbnail_url != ''" :src="product.back_thumbnail_url" style="background-color: #15256B;"/>
          <img v-else :src="product.product.json_data.back_thumbnail" style="background-color: #15256B"/>
        </figure>
      </div>
      <div class="column is-3 has-text-right is-hidden-mobile">
        <div class="text-wrapper is-inline-block ml-3">
          <p class="position">x {{product.amount}}</p>
        </div>
      </div>
      <div class="column is-3 has-text-right is-hidden-mobile">
        <div class="text-wrapper is-inline-block ml-3">
          <p class="position">{{ parseFloat(product.amount * this.$store.getters.getVolumeDiscount(product.product.name)).toFixed(2) }} €</p>
        </div>
      </div>
      <div class="column is-6 has-text-right is-hidden-tablet">
        <div class="text-wrapper">
          <p class="position">x {{product.amount}} = {{ parseFloat(product.amount * this.$store.getters.getVolumeDiscount(product.product.name)).toFixed(2) }} €</p>
        </div>
      </div>
    </div>

    <div v-if="discounts != null && discounts.code" class="columns is-multiline is-mobile">
      <div class="column is-12 has-text-right">
        <p>
          Kampanjakoodi {{discounts.code}}
        </p>
      </div>
    </div>

    <div v-if="getTotalFreightPrice>0 || (getOrderDeliveryType == 'MIXED' && getTotalFreightItemsInternational>0)" class="columns is-multiline is-mobile">
      <div class="column is-12 has-text-right" v-for="(product, index) in productsWithAddresses" :key="product.id">
        <p>
          {{addressParser(product)}} ({{getWeightForAddress(product)}}kg)
          <span v-if="product.delivery_address != null && product.address_type == 'INTERNATIONAL_FREIGHT'" class="subtotal">= Ulkomaan toimituskustannukset lisätään laskulle</span>
          <span v-else-if="product.delivery_address != null && product.address_type == 'FINLAND_FREIGHT'" class="subtotal">= {{getTotalFreightPriceForOneAddress(product)}} €</span>
        </p>
      </div>
    </div>
    <div v-if="getTotalMailboxItemsInternational>0" class="columns is-mobile">
      <div class="column is-12 has-text-right">
        <p>Toimitukset suoraan vastaanottajille ulkomaille ({{getTotalMailboxItemsInternational}}kpl) = Ulkomaan toimituskustannukset lisätään laskulle</p>
      </div>
    </div>
    <div v-if="getTotalMailboxPrice>0" class="columns is-mobile">
      <div class="column is-12 has-text-right">
        <p>Toimitukset suoraan vastaanottajille Suomeen ({{getTotalMailboxItemsFinland}}kpl): {{getTotalMailboxPrice}}€</p>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column is-12 has-text-right">
        <p>Yhteensä ({{getProductsLength}} tuotetta): {{total_amount}}€</p>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column is-6 has-text-left">
        <button
          :disabled="getSavedItems().length == 0"
          class="upload-button button is-primary"
          @click="resetCart()"
        >
          <span>Tee uusi tilaus</span>
      </button>
      </div>
      <div class="column is-6 has-text-right">
        <button
          :disabled="getSavedItems().length == 0"
          class="upload-button button is-primary"
          @click="toCartPage()"
        >
          <span>Ostoskoriin</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import asModalDialogMixin from '@/components/helpers/modalMixing'
import {mapGetters, mapMutations} from "vuex"

export default {
  name: 'cartModal',
  mixins: [asModalDialogMixin],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  methods: {
    ...mapGetters({
      getOrderData: 'getOrderData',
      getSavedItems: 'getSavedItems',
      getFreightPricePerWeight: 'getFreightPricePerWeight',
      getCalendarMode: 'getCalendarMode'
    }),
    ...mapMutations([
      'setSpinnerState',
      'setReseting',
      'resetData'
    ]),
    previewLogoCss(logo_url) {
      let css
      if (logo_url != null) {
        css = {
          'background-image': `url(${logo_url})`,
        }
      } else {
        css = {
          display: 'none',
        }
      }
      return css
    },
    toCartPage() {
      this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
    },
    resetCart() {
      const answer = window.confirm('Haluatko jatkaa edellisen tilauksen muokkausta vai aloittaa alusta? Mikäli olet ottanut edellisen tilauksen linkin talteen, pääset muokkaamaan sitä linkin kautta.')
      if (!answer) return false

      if (this.$route.name == 'Home') {
        this.resetData()
        window.location.reload()
      } else {
        this.setReseting(true)
        this.$router.push({name: 'Home'})
      }
    },
    getMarianneTitle(product) {
      if (product.front_side_picture_url == '' && product.back_side_picture_url == '') {
        return 'Malliteksti'
      }
      return product.title
    },
    getMarianneText(product) {
      if (product.front_side_picture_url == '' && product.back_side_picture_url == '') {
        return 'Tämä on malliteksti, jonka tilalle tulee haluamasi​\n' +
            '\n' +
            'teksti.  Merkkejä voi olla enintään 300.​\n' +
            '\n' +
            'Tekstiä voi olla enintään 6 riviä.'
      }
      return product.text
    },
    getMarianneSignature(product) {
      if (product.front_side_picture_url == '' && product.back_side_picture_url == '') {
        return 'Allekirjoitus'
      }
      return product.signature
    },
    get_freight_price(weight) {
      let freights = this.getFreightPricePerWeight().slice(0)
      freights = freights.reverse()
      if (freights.length == 0) {
        return 0
      }
      let valid_freight = freights[0]
      for (let i = 0; i < freights.length; i++) {
        if (weight < freights[i].weight_range) {
          valid_freight = freights[i]
        }
      }
      return valid_freight.base_price + (valid_freight.price * weight)
    },
    getWeightForAddress(product) {
      if (product.delivery_address != null) {
        let address_id = product.delivery_address.id
        let weight = 0
        for (let i = 0; i < this.getSavedItems().length; i++) {
          if (this.getOrderDeliveryType == 'MIXED' && (this.getSavedItems()[i].address_type == 'FINLAND_FREIGHT' || this.getSavedItems()[i].address_type == 'INTERNATIONAL_FREIGHT') && this.getSavedItems()[i].delivery_address != null && this.getSavedItems()[i].delivery_address.id == address_id) {
            weight += this.getSavedItems()[i].product.weight * this.getSavedItems()[i].amount
          }
        }
        return weight.toFixed(2)
      }
      return 0
    },
    addressParser(product) {
      return product.delivery_address.recv_name + ', ' + product.delivery_address.recv_street
      + ', ' + product.delivery_address.recv_zip  + ', ' + product.delivery_address.recv_town + ', ' + product.delivery_address.recv_phone
      + ', ' + product.delivery_address.recv_country
    },
    getTotalFreightPriceForOneAddress(product) {
      // check how many products are using the same address
      let weight = this.getWeightForAddress(product)
      let total = this.get_freight_price(weight)
      let result = parseFloat(total*1.14)
      let price = result.toFixed(2)
      return parseFloat(price).toFixed(2)
    },
  },
  computed: {
    getProductsLength() {
      return this.$store.getters.getProductsLength
    },
    total_amount() {
      if (this.$store.state.order == null || this.getSavedItems().length == 0) {
        return 0
      }
      let amount = this.$store.getters.getTotalAmountProducts
      amount += parseFloat(this.getTotalMailboxPrice) + parseFloat(this.getTotalFreightPrice)
      // apply voucher discount if exists
      if (this.discounts) {
        if (this.discounts.amount_off != null && this.discounts.amount_off != '') {
          amount -= this.discounts.amount_off * 0.01
        } else {
          amount -= (amount * this.discounts.percent_off) / 100
        }
      }
      return amount.toFixed(2)
    },
    discounts() {
      return this.$store.getters.getVoucherDiscount
    },
    products() {
      if (this.getOrderData() != null && this.getSavedItems()) return this.getSavedItems()
      else return []
    },
    total_pcs() {
      let total_pcs = 0
      this.products.forEach((product) => {
        total_pcs += product.amount
      })
      return total_pcs
    },
    getTotalWeight() {
      let weight = 0
      for (let i = 0; i < this.getSavedItems().length; i++) {
        if (this.getOrderDeliveryType == 'ALL_TO_SAME_ADDRESS' ||  (this.getOrderDeliveryType == 'MIXED' && this.getSavedItems()[i].address_type == 'FINLAND_FREIGHT')) {
          weight += this.getSavedItems()[i].product.weight * this.getSavedItems()[i].amount
        }
      }
      return weight.toFixed(2)
    },
    getTotalFreightPrice() {
      if (this.getCalendarMode()) {
        return 4.9
      }
      // if its option all to mailbox then it has no sense to calculate this
      if (this.getOrderDeliveryType != 'MIXED' && this.getOrderDeliveryType != 'ALL_TO_SAME_ADDRESS') {
        return 0
      }
      let price = 0
      if (this.getOrderDeliveryType == 'MIXED') { // on mixed we calculate the freight cost per address
        for (let i = 0; i < this.getSavedItems().length; i++) {
          // check how many products are using the same address
          if (this.getSavedItems()[i].address_type == 'FINLAND_FREIGHT') {
            let weight = this.getWeightForAddress(this.getSavedItems()[i])
            let total = this.get_freight_price(weight)
            let result = parseFloat(total * 1.14)
            price = parseFloat(result.toFixed(2)) + price
          }
        }
      } else if (this.getOrderDeliveryType == 'ALL_TO_SAME_ADDRESS') { // all the products in the same freight
        let weight = this.getTotalWeight
        if (weight == 0) {
          return 0
        } else {
          let total = this.get_freight_price(weight)
          let result = parseFloat(total*1.14)
          price = result.toFixed(2)
        }
      }
      return parseFloat(price).toFixed(2)
    },
    getTotalMailboxItemsFinland() {
      let total = 0
      for (let i = 0; i < this.getSavedItems().length; i++) {
        if (this.getOrderDeliveryType == 'ALL_TO_MULTIPLE_ADDRESS' || (this.getOrderDeliveryType == 'MIXED' && (this.getSavedItems()[i].address_type == 'FINLAND_MAILBOX'))) {
          total += this.getSavedItems()[i].amount
        }
      }
      return total
    },
    getTotalMailboxItemsInternational() {
      let total = 0
      for (let i = 0; i < this.getSavedItems().length; i++) {
        if (this.getOrderDeliveryType == 'MIXED' && this.getSavedItems()[i].address_type == 'INTERNATIONAL_MAILBOX') {
          total += this.getSavedItems()[i].amount
        }
      }
      return total
    },
    getTotalFreightItemsInternational() {
      let total = 0
      for (let i = 0; i < this.getSavedItems().length; i++) {
        if (this.getOrderDeliveryType == 'MIXED' && this.getSavedItems()[i].address_type == 'INTERNATIONAL_FREIGHT') {
          total += this.getSavedItems()[i].amount
        }
      }
      return total
    },
    getTotalMailboxPrice() {
      if (this.getCalendarMode()) {
        return 0
      }
      let total = this.getTotalMailboxItemsFinland * this.mailbox_price
      let result = parseFloat(total*1.14)
      return result.toFixed(2)
    },
    getOrderDeliveryType() {
      // on cart page is a local data variable, on shipment/payment page this comes from the order model
      return this.getOrderData().delivery_type
    },
    mailbox_price() {
      return this.$store.getters.getShippingPrice.price
    },
    productsWithAddresses() {
      const uuids = new Set()
      // select the products with address
      let tmpProducts = this.products.filter(function( product ) {
        return product.delivery_address !== undefined;
      })
      // remove duplicated addresses (several products pointing to the same address)
      return tmpProducts.filter(product => {
        if (product.delivery_address != null) {
          if (uuids.has(product.delivery_address.id)) {
            return false
          } else {
            uuids.add(product.delivery_address.id);
            return true
          }
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.is-layout-fixed{
  position: fixed !important;
}

.mobile-modal {
  .buttons {
    right: 0;
    top: 0;
    position: absolute;
  }
}

.popover-content.is-calendar {
  top: 79px;
}

.popover-content.is-calendar {
  width: 389px !important;
}

.popover-content {
  position: absolute;
  background-color: white;
  width: 500px;
  right: 35px;
  padding: 20px 20px 20px 20px;
  border: 1px solid black;
  top: 80px;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  @media (max-width: 768px) {
    width: 100%;
    right: 0;
  }
  p {
    font-size: 16px;
  }
}

.box-text {
  white-space: pre-wrap;
}

.box-text-wrapper {
  text-align: center;
}

.cart-logo-small {
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  &.portrait {
    right: 5px;
    bottom: 5px;
    width: 15px;
    height: 15px;
  }
  &.landscape {
    right: 8px;
    bottom: 8px;
    @media (max-width: 768px) {
      right: 5px;
      bottom: 5px;
    }
  }
}

span.position {
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.marianne-thumbnail {
  img {
    width: auto !important;
    margin-right: auto;
    margin-left: auto;
  }
}

.is-2by1 {
  margin-top: 20px;
}

</style>
