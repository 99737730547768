<template>
  <div class="editor">
    <SectionHeader></SectionHeader>
    <section class="hero is-fullheight" v-if="getOrderData()">
      <div class="hero-body pb-6">
        <div class="container is-fullwidth">
          <div class="columns">
            <div class="column is-8-desktop is-offset-2-desktop is-12-mobile is-12-tablet">
              <div class="columns is-mobile">
                <div class="column has-background-white is-full" ref="dominoEditorContainer" v-if="currentProduct != null && currentProduct.product.name == 'domino'">
                  <domino-editor
                    v-if="currentBox"
                    :productId="currentBox"
                    class="editor"
                    ref="dominoEditor"
                  >
                  </domino-editor>
                  <div v-else class="">
                    <h3>Valitse muokattava pakkauskuva</h3>
                  </div>
                </div>
                <div class="column has-background-white is-full" ref="calendarEditorContainer" v-if="currentProduct != null && (currentProduct.product.name == 'calendar' || currentProduct.product.name == 'calendar-b2b')">
                  <calendar-editor
                    v-if="currentBox"
                    :productId="currentBox"
                    class="editor"
                    ref="calendarEditor"
                  >
                  </calendar-editor>
                  <div v-else class="">
                    <h3>Valitse muokattava pakkauskuva</h3>
                  </div>
                </div>
                <div class="column has-background-white is-full" ref="fazerEditorContainer" v-else-if="currentProduct != null && currentProduct.product.name == 'blue'">
                  <fazer-editor
                    v-if="currentBox"
                    :productId="currentBox"
                    class="editor"
                    ref="fazerEditor"
                  >
                  </fazer-editor>
                  <div v-else class="">
                    <h3>Valitse muokattava pakkauskuva</h3>
                  </div>
                </div>
                <div class="column has-background-white is-full" ref="barEditorContainer" v-else-if="currentProduct != null && currentProduct.product.name == 'bar'">
                  <bar-editor
                    v-if="currentBox"
                    :productId="currentBox"
                    class="editor"
                    ref="barEditor"
                  >
                  </bar-editor>
                  <div v-else class="">
                    <h3>Valitse muokattava pakkauskuva</h3>
                  </div>
                </div>
                <div class="column has-background-white is-full" ref="marianneEditorContainer" v-else-if="currentProduct != null && currentProduct.product.name == 'marianne'">
                  <marianne-editor
                    v-if="currentBox"
                    :productId="currentBox"
                    class="editor"
                    ref="marianneEditor"
                  >
                  </marianne-editor>
                  <div v-else class="">
                    <h3>Valitse muokattava pakkauskuva</h3>
                  </div>
                </div>
                <div class="column has-background-white is-full" ref="customCandyBoxEditorContainer" v-else-if="currentProduct != null && currentProduct.product.name == 'custom-candy-box'">
                  <custom-candy-box-editor
                    v-if="currentBox"
                    :productId="currentBox"
                    class="editor"
                    ref="customCandyBoxEditor"
                  >
                  </custom-candy-box-editor>
                  <div v-else class="">
                    <h3>Valitse muokattava pakkauskuva</h3>
                  </div>
                </div>
              </div>

              <div class="columns" :class="{'calendar-editor-footer': currentProduct != null && currentProduct['product']['name'] == 'calendar'}">
                <div class="column">
                  <editor-footer v-if="currentProduct != null"
                    class="is-full"
                    :productId="currentBox"
                    :productType="currentProduct"
                    @continue-to-cart="toCartPage"
                    @continue-to-domino-editor="goToUploadPage"
                    @continue-to-calendar-editor="goToUploadPage"
                    @continue-to-fazer-editor="goToEditor"
                    @continue-to-bar-editor="goToEditor"
                    @continue-to-marianne-editor="goToEditor"
                    @continue-to-candy-box-editor="goToEditor"
                    @save-link="saveBox"
                  ></editor-footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </section>
  </div>
</template>

<script>
import FazerEditor from '@/components/FazerEditor'
import DominoEditor from '@/components/DominoEditor'
import CalendarEditor from "@/components/CalendarEditor"
import MarianneEditor from '@/components/MarianneEditor'
import CustomCandyBoxEditor from "@/components/CustomCandyBoxEditor"
import BarEditor from '@/components/BarEditor'
import SectionHeader from '@/components/SectionHeader'
import Footer from '@/components/Footer'
import editorFooter from '@/components/editorFooter'
import {mapMutations, mapGetters, mapActions} from 'vuex'
import {useMeta} from "vue-meta"

export default {
  name: 'EditorPage',
  components: {
    SectionHeader,
    DominoEditor,
    FazerEditor,
    BarEditor,
    MarianneEditor,
    CustomCandyBoxEditor,
    CalendarEditor,
    editorFooter,
    Footer,
  },
  data() {
    return {
      currentBox: null,
      currentProduct: null
    }
  },
  mounted() {
    if (this.getCalendarMode()) {
      useMeta({
        title: 'Fazer Oma kalenteri',
        description: 'Fazer Oma kalenteri palvelussa voit tilata itse suunnittelemasi suklaajoulukalenteri.'
      })
    }
    this.getProducts()
    window.scrollTo(0, 0)
    if (this.getOrderData() == null) {
      this.setReseting(true)
      this.$router.push({name: 'Home'})
    }
    else {
      let product = this.$store.getters.getProductById(this.$route.params.uuid)
      if (product == undefined) {
        product = this.getOrderData().products[0]
      }
      if (product.product.name == 'calendar-b2b' || product.product.name == 'calendar') {
        this.$router.push('/')
      }
      this.changeBox(product)
    }
    this.setSpinnerState(false)
    this.setCartSpinnerState(false)
  },
  methods: {
    ...mapMutations([
      'setSpinnerState',
      'setCartSpinnerState',
      'setReseting'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData',
      getProductId: 'getProductId',
      getCalendarMode: 'getCalendarMode'
    }),
    ...mapActions([
      'postFazerBox',
      'postFazerBar',
      'postMarianneBox',
      'postCustomCandyBox',
      'postCalendar',
      'getProducts'
    ]),
    changeBox(product) {
      if (product.id == this.currentBox) {
        if (this.currentProduct.product.name == 'domino') {
          this.$refs.dominoEditor.initEditorState(product.id)
        } else if (this.currentProduct.product.name == 'blue') {
          this.$refs.fazerEditor.initEditorState(product.id)
        } else if (this.currentProduct.product.name == 'bar') {
          this.$refs.barEditor.initEditorState(product.id)
        } else if (this.currentProduct.product.name == 'marianne') {
          this.$refs.marianneEditor.initEditorState(product.id)
        } else if (this.currentProduct.product.name == 'custom-candy-box') {
          this.$refs.customCandyBoxEditor.initEditorState(product.id)
        } else if (this.currentProduct.product.name == 'calendar' || this.currentProduct.product.name == 'calendar-b2b') {
          this.$refs.calendarEditor.initEditorState(product.id)
        }
      }
      this.currentBox = product.id
      this.currentProduct = this.$store.getters.getProductById(this.currentBox)
    },
    toCartPage() {
      this.setSpinnerState(true)
      if (this.currentProduct.product.name == 'domino') {
        this.$refs.dominoEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
        }).catch(() => {
          console.log('Product is not ready')
          }
        )
      } else if (this.currentProduct.product.name == 'blue'){
        this.$refs.fazerEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
        }).catch(() => {
          console.log('Product is not ready')
          }
        )
      } else if (this.currentProduct.product.name == 'bar') {
        this.$refs.barEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
        }).catch(() => {
          console.log('Product is not ready')
          }
        )
      } else if (this.currentProduct.product.name == 'marianne') {
        this.$refs.marianneEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
        }).catch(() => {
          console.log('Product is not ready')
          }
        )
      } else if (this.currentProduct.product.name == 'custom-candy-box') {
        this.$refs.customCandyBoxEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
        }).catch(() => {
          console.log('Product is not ready')
          }
        )
      } else if (this.currentProduct.product.name == 'calendar' || this.currentProduct.product.name == 'calendar-b2b') {
        this.$refs.calendarEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
        }).catch(() => {
          console.log('Product is not ready')
          }
        )
      }
    },
    goToEditor() {
      let self = this
      self.setSpinnerState(true)
      self.setCartSpinnerState(true)
      let data = {}
      let order_id = self.$store.state.order.id
      if (order_id != undefined) {
        data['order_id'] = order_id
      }

      if (this.currentProduct.product.name == 'domino') {
        this.$refs.dominoEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          data['product'] =  this.$store.getters.getProductId('domino')
          data['draft'] = true
          data['type'] = 'ALL_TO_SAME_ADDRESS'
          this.postFazerBox(data).then(() => {
            self.setSpinnerState(false)
            self.setCartSpinnerState(false)
            self.$router.push({name: 'editor', params: {uuid: self.$store.state.postFazerBoxData.last_product}}).then(() => {
              window.location.reload()
            })
          })
        })
      } else if (this.currentProduct.product.name == 'blue'){
        this.$refs.fazerEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          data['product'] =  this.$store.getters.getProductId('blue')
          data['draft'] = true
          data['type'] = 'ALL_TO_SAME_ADDRESS'
          this.postFazerBox(data).then(() => {
            self.setSpinnerState(false)
            self.setCartSpinnerState(false)
            self.$router.push({name: 'editor', params: {uuid: self.$store.state.postFazerBoxData.last_product}}).then(() => {
              window.location.reload()
            })
          })
        })
      }
      else if (this.currentProduct.product.name == 'bar') {
        this.$refs.barEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          data['product'] =  this.$store.getters.getProductId('bar')
          data['draft'] = true
          data['type'] = 'ALL_TO_SAME_ADDRESS'
          data['csv_status'] = 'NO_CSV_NEEDED'
          this.postFazerBar(data).then(() => {
            self.setSpinnerState(false)
            self.setCartSpinnerState(false)
            self.$router.push({name: 'editor', params: {uuid: self.$store.state.postFazerBarData.last_product}}).then(() => {
              window.location.reload()
            })
          })
        })
      }
      else if (this.currentProduct.product.name == 'marianne') {
        this.$refs.marianneEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          data['product'] =  this.$store.getters.getProductId('marianne')
          data['draft'] = true
          data['type'] = 'ALL_TO_SAME_ADDRESS'
          data['csv_status'] = 'NO_CSV_NEEDED'
          this.postMarianneBox(data).then(() => {
            self.setSpinnerState(false)
            self.setCartSpinnerState(false)
            self.$router.push({name: 'editor', params: {uuid: self.$store.state.postMarianneBoxData.last_product}}).then(() => {
              window.location.reload()
            })
          })
        })
      }
      else if (this.currentProduct.product.name == 'custom-candy-box') {
        this.$refs.customCandyBoxEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          data['product'] =  this.$store.getters.getProductId('custom-candy-box')
          data['draft'] = true
          data['type'] = 'ALL_TO_SAME_ADDRESS'
          data['csv_status'] = 'NO_CSV_NEEDED'
          this.postCustomCandyBox(data).then(() => {
            self.setSpinnerState(false)
            self.setCartSpinnerState(false)
            self.$router.push({name: 'editor', params: {uuid: self.$store.state.postCustomCandyBoxData.last_product}}).then(() => {
              window.location.reload()
            })
          })
        })
      }
      else if (this.currentProduct.product.name == 'calendar' || this.currentProduct.product.name == 'calendar-b2b') {
        this.$refs.calendarEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          data['product'] =  this.$store.getters.getProductId(this.currentProduct.product.name)
          data['draft'] = true
          data['type'] = 'ALL_TO_SAME_ADDRESS'
          data['csv_status'] = 'NO_CSV_NEEDED'
          this.postCalendar(data).then(() => {
            self.setSpinnerState(false)
            self.setCartSpinnerState(false)
            self.$router.push({name: 'editor', params: {uuid: self.$store.state.postCalendarData.last_product}}).then(() => {
              window.location.reload()
            })
          })
        })
      }      
    },
    goToUploadPage() {
      this.setSpinnerState(true)
      if (this.currentProduct.product.name == 'domino') {
        this.$refs.dominoEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'upload', params: { product: 'domino' } })
        })
      } else if (this.currentProduct.product.name == 'calendar' || this.currentProduct.product.name == 'calendar-b2b') {
        this.$refs.calendarEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'upload', params: { product: 'calendar' } })
        })
      }
    },
    saveBox() {
      if (this.currentProduct.product.name == 'domino') {
        this.$refs.dominoEditor.saveBox()
      } else if (this.currentProduct.product.name == 'blue') {
        this.$refs.fazerEditor.saveBox()
      } else if (this.currentProduct.product.name == 'bar') {
        this.$refs.barEditor.saveBox()
      } else if (this.currentProduct.product.name == 'marianne') {
        this.$refs.marianneEditor.saveBox()
      } else if (this.currentProduct.product.name == 'custom-candy-box') {
        this.$refs.customCandyBoxEditor.saveBox()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.hero-body {
  background-color: #EBEFF5;
  .container {
    background-color: #EBEFF5;
  }
}

@media screen and (min-width: 769px) {
  .margin-l-desktop-2 {
    margin-left: 2%;
  }
}

.calendar-editor-footer {
  height: 100px;
}

</style>
