import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


const app = createApp(App)

app.use(store)
app.use(router)
app.use(createMetaManager())
app.component('VueDatePicker', VueDatePicker)
app.mount('#app')

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
